import React, { useState, useEffect } from "react";
import { INewDashboardPageProps } from "./types";
import "./style.scss";
import rootReducer from "../../../store/reducers";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { OutLineButton, PrimaryButton, MaterialUIPickers, CustomModal, CustomText } from "@components";
import { useDispatch, useSelector } from "react-redux";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer,
  LabelList, Tooltip
} from 'recharts';
import { ProfileAction } from "@temp/store/actions";
import moment from "moment";

import { Grid, MenuItem, Select } from "@mui/material/";
import { showMessage } from "@temp/utils/helper";
import InfoIcon from "@mui/icons-material/Info";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { PaginatedData } from "../../LoadDetail/components/PaginatedData";
export type RootState = ReturnType<typeof rootReducer>;
var numeral = require("numeral");


const Page: React.FC<INewDashboardPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const activeCategory = useSelector((state: RootState) => state.Profile?.activeCategoryReports);
  const activeDateFilter = useSelector((state: RootState) => state.Profile?.activeDateFilter);
  const fromResponseDate = useSelector((state: RootState) => state.Profile?.fromResponseDate);
  const responseDate = useSelector((state: RootState) => state.Profile?.responseDate);
  const chart_data = useSelector((state: RootState) => state.Profile?.chart_data);
  const [showModal, setShowModal] = useState(false);
  const profile = useSelector((state: RootState) => state.Profile.profile);

  const new_dashboard_report = useSelector((state: RootState) => state.Profile?.new_dashboard_report);
  const cb = () => {
    history.push("/dashboard-vehicle-details");
  };
 

  const formattedData = chart_data?.map((entry) =>
  ({
    ...entry,
    date: entry?.date,

  }))

  const sortedData = formattedData

  // for minimize six months date

  useEffect(() => {
    let date_from = moment(Date.now() - 0 * 24 * 3600 * 1000).format('YYYY-MM-DD')
    let date_to = moment().format("YYYY-MM-DD")
    if (activeDateFilter == "weekly") {
      date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateFilter == "monthly") {
      date_from = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateFilter == "today") {
      date_from = moment(Date.now()).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    }
    else {
      date_from = moment(fromResponseDate).format('YYYY-MM-DD')
      date_to = moment(responseDate).format('YYYY-MM-DD')
    }
    dispatch(ProfileAction.dashboardDataReportNew({
      loads_by: activeCategory,
      date_range_from: date_from,
      date_range_to: date_to,
      date_type: activeDateFilter,
      stats: "dashboard",
      search: "",
      vehicle_type: "",
      next_page: 1
    }))
  }, []);

  const callBack = (param) => {

    if (param == "422") {
      setShowModal(true)
    }
  }

  const handleSubmit = () => {
    dispatch(ProfileAction.dashboardDataReportNew({
      loads_by: activeCategory,
      date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
      date_range_to: moment(responseDate).format("YYYY-MM-DD"),
      date_type: activeDateFilter,
      stats: "dashboard",
      search: "",
      vehicle_type: "",
      next_page: 1,
      callBack
    }))
  }

  const handleClickCategory = (type: string) => {
    dispatch(ProfileAction.setReportsFilter({
      activeCategoryReports: type,
      activeDateFilter,
      fromResponseDate,
      responseDate,
    }))
    if (activeDateFilter == 'weekly') {
      let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateFilter,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (activeDateFilter == 'monthly') {
      let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateFilter,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (activeDateFilter == "today") {
      let start_date = moment(Date.now()).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateFilter,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))

    }
    else if (activeDateFilter == 'date') {
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: type,
        date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
        date_range_to: moment(responseDate).format("YYYY-MM-DD"),
        date_type: activeDateFilter,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1

      }))
    }
  };

  const handleDateFilter = (type) => {
    dispatch(ProfileAction.setReportsFilter({
      activeCategoryReports: activeCategory,
      activeDateFilter: type,
      fromResponseDate,
      responseDate,
    }))
    if (type == 'weekly') {
      let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: activeCategory,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (type == 'monthly') {
      let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: activeCategory,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    }
    else if (type == 'today') {
      let start_date = moment(Date.now()).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: activeCategory,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))

    }
    else if (type == 'date') {

      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: activeCategory,
        date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
        date_range_to: moment(responseDate).format("YYYY-MM-DD"),
        date_type: type,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1

      }))
    }
  }
  const getSummaryText = () => {

    switch (activeDateFilter) {
      case "today":
        return "Today";
      case "weekly":
        return "Week";
      case "monthly":
        return "Month";
      case "date":
        return "Date"
      default:
        return "Today";
    }
  };

  const summaryText = getSummaryText();

  return (
    <div style={{ height: 'calc(100vh - 15px)', overflow: 'auto' }} className="root-center-container">
      <div className="loads-top-wrappers">
        <div className="topDropDownWrap">
          <div
            className="drop-down"
            style={{ justifyContent: "space-between" }}
          >
            <button onClick={() => {
              history.goBack()
            }} className="header-buttons">
              <img
                src={Images.BackArrow}
                alt="Back"
              />
            </button>
            <p className="title">Dashboard</p>
            <div>
              <CloudDownloadOutlined style={{ cursor: "pointer" }}
                onClick={() => {
                  if (activeDateFilter == 'weekly') {
                    let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
                    let end_date = moment().format('YYYY-MM-DD');
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: start_date,
                      date_range_to: end_date,
                      date_type: activeDateFilter,
                      report: "dashboard",
                      search: "",
                      vehicle_type: ""
                    }))
                  } else if (activeDateFilter == 'monthly') {
                    let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
                    let end_date = moment().format('YYYY-MM-DD');
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: start_date,
                      date_range_to: end_date,
                      date_type: activeDateFilter,
                      report: "dashboard",
                      search: "",
                      vehicle_type: ""
                    }))
                  } else if (activeDateFilter == 'date') {
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
                      date_range_to: moment(responseDate).format("YYYY-MM-DD"),
                      date_type: activeDateFilter,
                      report: "dashboard",
                      search: "",
                      vehicle_type: ""
                    }))
                  } else if (activeDateFilter == 'today') {
                    let start_date = moment(Date.now()).format('YYYY-MM-DD')
                    let end_date = moment().format('YYYY-MM-DD');
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: start_date,
                      date_range_to: end_date,
                      date_type: activeDateFilter,
                      report: "dashboard",
                      search: "",
                      vehicle_type: ""
                    }))
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="tabContainerMainWrap reportLoadListing mtopZero">
          <div className="newDashaboardHeading">
            <div className="welcomeDashboardText">Welcome {profile?.name}</div>
            <div className="taglineDashboardText">

              {/* Your Summary for the <span>{getSummaryText()}</span> */}
              {activeDateFilter === "today" ? (
                <>
                  Your Summary for <span>{summaryText}</span>
                </>
              ) :
                activeDateFilter === "date" ? (
                  <>

                    Your Summary for <span>{`${moment(fromResponseDate).format('ll')} to ${moment(responseDate).format('ll')}`}</span>
                  </>
                ) :

                  (
                    <>
                      Your Summary for the <span>{summaryText}</span>
                    </>
                  )}
            </div>
          </div>
          {/* Top Tabs Posted recieved  */}
          {
            <FadeInComponent>
              <div className={`tab-btn radioTabs twoTab`}>
                <div
                  onClick={() => {
                    handleClickCategory("posted");
                  }}
                  className={`two-category-button ${activeCategory === "posted" ? "active" : ""
                    }`}
                >
                  Posted
                </div>
                <div
                  onClick={() => {
                    handleClickCategory("received");
                  }}
                  className={`two-category-button ${activeCategory === "received" ? "active" : ""
                    }`}
                >
                  Received
                </div>
                <div className="radioTab_slider"></div>
              </div>
            </FadeInComponent>
          }

          {/* Top Tabs date filters  */}
          {/* <div className="tabContainerMainWrap reportLoadListing" style={{ display: 'none' }}>
            {
              <FadeInComponent className='pr-2'>
                <div className={`tab-btn radioTabs twoTab`}>
                  <div
                    onClick={() => {
                      handleDateFilter("today");
                    }}
                    className={`two-category-button ${activeDateFilter === "today" ? "active" : ""
                      }`}
                  >
                    Today
                  </div>
                  <div
                    onClick={() => {
                      handleDateFilter("weekly");
                    }}
                    className={`two-category-button ${activeDateFilter === "weekly" ? "active" : ""
                      }`}
                  >
                    Weekly
                  </div>
                  <div
                    onClick={() => {
                      handleDateFilter("monthly");
                    }}
                    className={`two-category-button ${activeDateFilter === "monthly" ? "active" : ""
                      }`}
                  >
                    Monthly
                  </div>

                  <div className="radioTab_slider"></div>
                </div>
              </FadeInComponent>
            }
          </div> */}
          <div className="newDashboarSelectDate">
            <div className="tabContainerMainWrap reportLoadListing">
              {/* <Select
                id="outlined-select-date-native"
                select
                defaultValue={activeDateFilter}
                name=""
                onChange={(e) => handleDateFilter(e.target.value)}
              >
                <MenuItem value={'today'}>
                  Today
                </MenuItem>
                <MenuItem value={'weekly'}>
                  Weekly
                </MenuItem>
                <MenuItem value={'monthly'}>
                  Monthly
                </MenuItem>
                <MenuItem value={'date'}>
                  Date Filter
                </MenuItem>
              </Select> */}

              <div className="dashboardSelectDate">
                <span className="selectDateHeading">Select by date</span>
                <div className="calendarIcon">
                  <CalendarMonthIcon
                    onClick={(e) => {
                      setShowModal(true)
                      handleDateFilter("date")
                    }}
                  />
                </div>
              </div>

              <FadeInComponent>
                <div
                  // className={`tab-btn radioTabs twoTab`}
                  className="tab-btn dashboardTabBtn"
                >
                  <div
                    onClick={() => {
                      handleDateFilter("today");
                    }}
                    className={`two-category-button ${activeDateFilter === "today" ? "active" : ""
                      }`}
                  >
                    Today
                  </div>
                  <div
                    onClick={() => {
                      handleDateFilter("weekly");
                    }}
                    className={`two-category-button ${activeDateFilter === "weekly" ? "active" : ""
                      }`}
                  >
                    Weekly
                  </div>
                  <div
                    onClick={() => {
                      handleDateFilter("monthly");
                    }}
                    className={`two-category-button ${activeDateFilter === "monthly" ? "active" : ""
                      }`}
                  >
                    Monthly
                  </div>

                  <div className="radioTab_slider"></div>
                </div>
              </FadeInComponent>

              {/* {
                activeDateFilter == "date" ?
                  <FadeInComponent className="dateLabel">
                    <div className="resports-date-filter-wraper">
                      <div onClick={() => {
                        setShowModal(true)

                      }} style={{ border: `1px solid ${Colors.NavyBlue}` }} className="reports-date-filter">
                        <span className="icons-calender calenderIcon"></span>
                        <p style={{ color: Colors.NavyBlue, marginTop: 4 }}>DATE FILTER</p>
                      </div>
                      <div>
                        {
                          fromResponseDate && responseDate ?
                            <p className="label">{`${moment(fromResponseDate).format('ll')} - ${moment(responseDate).format('ll')}`}</p> : null
                        }
                      </div>
                    </div>
                  </FadeInComponent>
                  : null} */}
            </div>
          </div>
        </div>
      </div>
      <div className="load-listing-container remCustomPadonMob tabContainerMainWrap reportLoadListing"
        style={{
          paddingTop: activeDateFilter == "date" ? 265 : 265
        }}>
        <Grid container className="graphWrapper newDashboardWrapper">
          {/* {
            sortedData?.length > 0 ?
              <>
                <div className={activeDateFilter === "today" ? "todayDataWrap" : ""}>
                  <ul className="recharts-default-legend" style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
                    <li className="recharts-legend-item legend-item-1" style={{ display: "inline-block", marginRight: "10px" }}>
                      <span className="recharts-surface-circle" style={{ background: "#689ad0" }}></span>
                      <span className="recharts-legend-item-text" style={{ color: "#689ad0" }}>
                        {activeCategory === "posted" ? "Posted" : "Received"} - Spot
                      </span>
                    </li>
                    <li className="recharts-legend-item legend-item-0" style={{ display: "inline-block", marginRight: "10px" }}>
                      <span className="recharts-surface-circle" style={{ background: "#0d5296" }}></span>
                      <span className="recharts-legend-item-text" style={{ color: "#0d5296" }}>
                        {activeCategory === "posted" ? "Posted" : "Received"} - Load (Against Contract)
                      </span>
                    </li>
                    <li className="recharts-legend-item legend-item-3" style={{ display: "inline-block", marginRight: "10px" }}>
                      <span className="recharts-surface-circle" style={{ background: "#7ac251" }}></span>
                      <span className="recharts-legend-item-text" style={{ color: "#7ac251" }}>Awarded - Spot</span>
                    </li>
                    <li className="recharts-legend-item legend-item-2" style={{ display: "inline-block", marginRight: "10px" }}>
                      <span className="recharts-surface-circle" style={{ background: "#4b9c1c" }}></span>
                      <span className="recharts-legend-item-text" style={{ color: "#4b9c1c" }}>Awarded - Load (Against Contract)</span>
                    </li>
                  </ul>
                </div>
                <div id="bardiv">
                  <div style={{ paddingTop: '0px' }}>

                    <div className={activeDateFilter === "today" ? "todayDataWrap" : ""} style={{ width: activeDateFilter == "today" ? "100%" : "600px" }}>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={sortedData} barSize={40}>
                          <CartesianGrid />

                          <XAxis
                            dataKey="date"
                            tickFormatter={
                              sortedData?.map((item) => {
                                item?.date
                              })
                            }
                          />
                          <YAxis />
                          {Object?.keys(sortedData[0])?.slice(1).map((recordType, index) => {

                            // Filter to include only "Posted" and "Awarded"
                            if (recordType === "Posted" || recordType === "Awarded") {
                              return (
                                <React.Fragment key={recordType}>
                                  <Bar
                                    dataKey={`${recordType}.Contract`}
                                    stackId={recordType}
                                    fill={index === 0 ? "#0d5296" : "#4b9c1c"}
                                    isAnimationActive={false}  // Disable animation on hover
                                  >
                                    <LabelList
                                      dataKey={`${recordType}.Contract`}
                                      position="center"
                                      // formatter={(value) => `${value}`}
                                      formatter={(value) => value > 0 ? `${value}` : ''}
                                    />
                                  </Bar>
                                  <Bar
                                    dataKey={`${recordType}.Spot`}
                                    stackId={recordType}
                                    fill={index === 0 ? "#689ad0" : "#7ac251"}
                                    isAnimationActive={false}  // Disable animation on hover
                                  >
                                    <LabelList
                                      dataKey={`${recordType}.Spot`}
                                      position="center"
                                      formatter={(value) => value > 0 ? `${value}` : ''}
                                    />
                                  </Bar>
                                </React.Fragment>
                              );
                            }
                            return null;
                          })}
                          <Tooltip
                            content={({ label, payload }) => {


                              if (payload && payload.length > 0) {
                                const tooltipContent = payload.reduce((acc, entry) => {
                                  const [type, attribute] = (entry.dataKey as string).split('.');
                                  const formattedAttribute = (attribute === 'Contract') ? `Load (Against Contract):` : attribute;

                                  if (!acc[type]) {
                                    acc[type] = [];
                                  }
                                  acc[type].push(`${formattedAttribute} ${entry.value}`);
                                  return acc;
                                }, {});


                                return (
                                  <div className="custom-tooltip" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}>
                                    <div style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }}>
                                      <CustomText.OpenSansBold>
                                        Date:
                                      </CustomText.OpenSansBold>
                                      <CustomText.OpenSansRegular textStyle={{
                                        marginTop: 2,
                                        marginLeft: 2
                                      }}>
                                        <span className="customFontSize">{`${label}`}</span>
                                      </CustomText.OpenSansRegular>
                                    </div>
                                    {Object.entries(tooltipContent).map(([type, values]) => (

                                      // 
                                      <p key={type}>
                                        <strong>
                                          {activeCategory === 'received' && type === 'Posted' ? 'Received' : type}:
                                        </strong>{" "}
                                        <span className="customFontSize">{values.join(', ')}</span>
                                      </p>
                                    ))}
                                  </div>
                                );
                              }
                              return null;
                            }}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </>
              : "No data found"
          } */}

          {/*grey box data Start*/}
          {/* <div className="greyBoxWithData">
            <div className="insideGreyBox">
              <div className="smallGreyData">
                <label>Load</label>
                <p>{
                  activeCategory === "posted" ? "Posted" : "Received"}
                </p>
                <p>Awarded</p>
              </div>

              <div className="smallGreyData">
                <label>Spot</label>
                <p>{new_dashboard_report?.PostedAwardedCounts?.posted_spot ?
                  new_dashboard_report?.PostedAwardedCounts?.posted_spot : 0}</p>

                <p>{new_dashboard_report?.PostedAwardedCounts?.awarded_spot ?
                  new_dashboard_report?.PostedAwardedCounts?.awarded_spot : 0
                }</p>
              </div>

              <div className="smallGreyData customSpotGreyData">
                <label><span className="insideLabel">Load</span> <span className="insideLabelText">(Aganist Contract)</span></label>
                <p>{new_dashboard_report?.PostedAwardedCounts?.posted_contract ?
                  new_dashboard_report?.PostedAwardedCounts?.posted_contract : 0
                }</p>

                <p>{new_dashboard_report?.PostedAwardedCounts?.awarded_contract ?
                  new_dashboard_report?.PostedAwardedCounts?.awarded_contract : 0
                }</p>
              </div>

              <div className="smallGreyData">
                <label>Total</label>
                <p>
                  {new_dashboard_report?.PostedAwardedCounts?.posted_total}
                </p>
                <p>{new_dashboard_report?.PostedAwardedCounts?.awarded_total}</p>
              </div>

            </div>
          </div> */}
          {/*grey box data end*/}

          <div className="dataWithStatsWrap greyDataWithStatsWrap newdashboardBlueDataWithStatsWrap">
            <h2 className="statsHeading">Overview</h2>
            <div className="insideDataStats">
              <div className="insideDataBox">
                <label>Loads</label>
                <p>{new_dashboard_report?.OverView?.loads ?
                  new_dashboard_report?.OverView?.loads : 0
                }</p>
              </div>
              <div className="insideDataBox">
                <label>Vehicles</label>
                <p>{new_dashboard_report?.OverView?.vehicle ?
                  new_dashboard_report?.OverView?.vehicle : 0

                }</p>
              </div>
              <div className="insideDataBox">
                <label>Containers</label>
                <p>{new_dashboard_report?.OverView?.containers ?
                  new_dashboard_report?.OverView?.containers : 0

                }</p>
              </div>
              <div className="insideDataBox">
                <label>Weight <span className="smallTonText">(Ton)</span></label>
                <p>{numeral(new_dashboard_report?.OverView?.wight_ton).format("0,0")}</p>
              </div>

              <div className="insideDataBox">
                <label>Amount <span className="smallTonText">(PKR)</span></label>
                <p>{new_dashboard_report?.OverView?.amount ?
                  numeral(new_dashboard_report?.OverView?.amount).format("0,0.00") : 0

                }</p>
              </div>
              {/* <div className="insideDataBox">
                <label>Active Loads </label>
                <p>{numeral(new_dashboard_report?.LoadTypeCounts?.containerized?.amount).format("0,0")}</p>
              </div> */}
            </div>
          </div>

          {/*data with Stats Wrap Start*/}
          <div className="dataWithStatsWrap greyDataWithStatsWrap newdashboardBlueDataWithStatsWrap">
            <h2 className="statsHeading">Containerized</h2>
            <div className="insideDataStats">
              <div className="insideDataBox">
                <label>Load</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.containerized?.loads ?
                  new_dashboard_report?.LoadTypeCounts?.containerized?.loads : 0
                }</p>
              </div>
              <div className="insideDataBox">
                <label>Awarded</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.containerized?.awarded ?
                  new_dashboard_report?.LoadTypeCounts?.containerized?.awarded : 0

                }</p>
              </div>
              <div className="insideDataBox">
                <label>Weight <span className="smallTonText">(Ton)</span></label>
                <p>{numeral(new_dashboard_report?.LoadTypeCounts?.containerized?.weight_awarded).format("0.000")}</p>
              </div>
              <div className="insideDataBox">
                <label>Vehicles</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.containerized?.vehicles ?
                  new_dashboard_report?.LoadTypeCounts?.containerized?.vehicles : 0

                }</p>
              </div>
              <div className="insideDataBox">
                <label>Containers</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.containerized?.container ?
                  new_dashboard_report?.LoadTypeCounts?.containerized?.container : 0

                }</p>
              </div>
              <div className="insideDataBox">
                <label>Amount <span className="smallPKRText">(PKR)</span></label>
                <p>{numeral(new_dashboard_report?.LoadTypeCounts?.containerized?.amount).format("0,0")}</p>
              </div>
            </div>
          </div>
          <div className="dataWithStatsWrap greyDataWithStatsWrap newdashboardBlueDataWithStatsWrap">
            <h2 className="statsHeading">Non - Containerized</h2>
            <div className="insideDataStats">
              <div className="insideDataBox">
                <label>Load</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.non_containerized?.loads ?
                  new_dashboard_report?.LoadTypeCounts?.non_containerized?.loads : 0

                }</p>
              </div>

              <div className="insideDataBox">
                <label>Awarded</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.non_containerized?.awarded ?
                  new_dashboard_report?.LoadTypeCounts?.non_containerized?.awarded : 0

                }</p>
              </div>

              <div className="insideDataBox">
                <label>Weight <span className="smallTonText">(Ton)</span></label>
                <p>{`${numeral(new_dashboard_report?.LoadTypeCounts?.non_containerized?.weight_awarded).format("0.000")} `}</p>
              </div>

              <div className="insideDataBox">
                <label>Vehicles</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.non_containerized?.vehicles ?
                  new_dashboard_report?.LoadTypeCounts?.non_containerized?.vehicles : 0
                }</p>
              </div>



              <div className="insideDataBox">
                {/* <label>Items</label> */}
                <label>Containers</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.non_containerized?.item ?
                  new_dashboard_report?.LoadTypeCounts?.non_containerized?.item : 0
                }</p>
              </div>

              <div className="insideDataBox">
                <label>Amount <span className="smallPKRText">(PKR)</span></label>
                <p>{numeral(new_dashboard_report?.LoadTypeCounts?.non_containerized?.amount).format("0,0")}</p>
              </div>
            </div>
          </div>

          <div className="flexCol">
            <div className="pagiatedDataWrap">
              <PaginatedData data={new_dashboard_report} />
            </div>
            {/*data with Stats Wrap End*/}

            <div className="loadStatusHeading">Load Status</div>
          </div>

          {/*blue box data start */}
          <div className="error-container info customInfoText dashboardCustomInfoText">
            <div>
              <InfoIcon className="error-icon" />
            </div>
            <div className="error-text">
              {
                activeCategory == "posted" ? " Filters not applied on Open, Awarded & Active Tabs"
                  : "Filters not applied on New, Open, Awarded & Active Tabs"
              }
            </div>
          </div>
          <div className="blueBoxDataBoxesWrap">
            {
              activeCategory == "received" ?
                <div className="blueBox" style={{
                  cursor: "pointer"
                }}
                  onClick={() => {
                    history.push('/dashboard/spot-recieved/new', { showTabs: true })
                  }}
                >
                  <label>New</label>
                  <span>{new_dashboard_report?.tabCounts?.new ? new_dashboard_report?.tabCounts?.new : 0}</span>
                </div>
                : null}

            <div className="blueBox" style={{
              cursor: "pointer"
            }}
              onClick={() => {
                activeCategory == "posted" ?
                  history.push('/dashboard/spot-posted/open', { showTabs: true }) :
                  history.push('/dashboard/spot-recieved/open', { showTabs: true })
              }}
            >
              <label
              >Open</label>
              <span>{new_dashboard_report?.tabCounts?.open ? new_dashboard_report?.tabCounts?.open : 0}</span>
            </div>

            <div className="blueBox"
              style={{
                cursor: "pointer"
              }}
              onClick={() => {
                activeCategory == "posted" ?
                  history.push('/dashboard/spot-posted/awarded', { showTabs: true }) :
                  history.push('/dashboard/spot-recieved/awarded', { showTabs: true })
              }}
            >
              <label>Awarded</label>
              <span>{new_dashboard_report?.tabCounts?.awarded ? new_dashboard_report?.tabCounts?.awarded : 0}</span>
            </div>

            <div className="blueBox" style={{
              cursor: "pointer"
            }}
              onClick={() => {
                dispatch(ProfileAction.updateActiveFilters({
                  loadType: activeCategory == "posted" ? "Loads Posted" : "Loads Received",
                }))
                history?.push("/active-loads")
              }}
            >
              <label>Active</label>
              <span>{new_dashboard_report?.tabCounts?.active ? new_dashboard_report?.tabCounts?.active : 0}</span>
            </div>

          </div>
          {/*blue box data end */}
          <div className="bottomButtonsWrap">
            <PrimaryButton
              title={"Vehicles Details"}
              onClick={() => {
                dispatch(ProfileAction.setVehicleFilter({
                  activeCategoryVehicle: "posted",
                  activeDateVehicle: 'today',
                  fromResponseDateVehicle: new Date(),
                  responseDateVehicle: new Date(),
                }))
                dispatch(ProfileAction.JobLoadMeta(cb));
              }}

            />
            <PrimaryButton
              title={"Loads Details"}
              onClick={() => {
                dispatch(ProfileAction.setDetailFilter({
                  activeCategoryDetail: "posted",
                  activeDateDetail: 'today',
                  fromResponseDateDetail: new Date(),
                  responseDateDetail: new Date(),
                }))
                setTimeout(() => {
                  history.push("/dashboard-load-details");
                }, 300)
              }}

            />
          </div>
        </Grid>
      </div>
      <CustomModal showModal={showModal} toggleModal={setShowModal}>
        <div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 0
          }}>
            <div className="error-container info customInfoText dashboardCustomModalInfoText">
              <div>
                <InfoIcon className="error-icon" />
              </div>
              <div className="error-text">
                You can select upto 6 months data
              </div>
            </div>
            <span
              style={{
                right: 15,
                top: 30
              }}
              className="icons-cross directionRight"
              onClick={() => setShowModal(false)}
            ></span>
          </div>
          <div style={{ marginBottom: 10, marginTop: 15 }} className="inputField">
            <div className="label">From</div>
            <div className="DatePickerFieldWrap">
              <MaterialUIPickers
                date={fromResponseDate}
                setDate={(e) => {
                  if (e > responseDate) {

                    showMessage.current({
                      message:
                        "From date should be less than To date.",
                      status: 'error'
                    })
                  }
                  dispatch(ProfileAction.setReportsFilter({
                    activeCategoryReports: activeCategory,
                    activeDateFilter: activeDateFilter,
                    fromResponseDate: e,
                    responseDate,
                  }))
                }}
                minDate={"1900/01/01"}
                maxDate={new Date()}
              />
            </div>
          </div>
          <div style={{ marginBottom: 10, pointerEvents: fromResponseDate ? "auto" : 'none' }} className="inputField">
            <div className="label">To</div>
            <div className="DatePickerFieldWrap">
              <MaterialUIPickers
                date={responseDate}
                setDate={(e) => {
                  if (fromResponseDate > e) {

                    showMessage.current({
                      message:
                        "From date should be less than To date.",
                      status: 'error'
                    })
                  }
                  dispatch(ProfileAction.setReportsFilter({
                    activeCategoryReports: activeCategory,
                    activeDateFilter: activeDateFilter,
                    fromResponseDate: fromResponseDate,
                    responseDate: e,
                  }))
                }}
                minDate={"1900/01/01"}
                maxDate={new Date()}
              />
            </div>
          </div>

          <OutLineButton
            classProp='tinyBtn'
            title="Apply"
            onClick={() => {
              handleSubmit()
              setShowModal(false)
            }}
            buttonStyle={{
              height: 36,
              width: '100%'
            }}
            disabled={fromResponseDate > responseDate ? true : false}
          />
        </div>
      </CustomModal>
    </div>
  );

};
export default Page;
