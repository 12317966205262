
// new changes in code with pagination issues fixed (scroll and pagination are dependent)

import React, { useState } from "react";
import { Box, Typography, Card, CardContent, IconButton, Pagination, PaginationItem } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export const PaginatedData = ({ data }) => {
  var numeral = require("numeral");

  const [currentPage, setCurrentPage] = useState(1);
  const [animationDirection, setAnimationDirection] = useState("");

  // old data but changes done due to sentry error
  
  // const vendors = data?.vendors || [];
  // const visibleRecords = 3; // Visible columns
  // const totalRecords = vendors.length;
  // const totalPages = Math.ceil(totalRecords / visibleRecords);

  // const getPageData = (page) => {
  //   const start = (page - 1) * visibleRecords;
  //   const end = start + visibleRecords;
  //   return vendors.slice(start, end); // Data for the current page
  // };

  // const pageData = getPageData(currentPage);

  const vendors = Array.isArray(data?.vendors) ? data.vendors : [];
  const visibleRecords = 3; // Visible columns
  const totalRecords = vendors.length;
  const totalPages = Math.ceil(totalRecords / visibleRecords);

  const getPageData = (page) => {
    const start = (page - 1) * visibleRecords;
    const end = start + visibleRecords;
    return vendors.slice(start, end); // Data for the current page
  };

  const pageData = getPageData(currentPage);

  const handleNextSlide = () => {
    if (currentPage < totalPages) {
      setAnimationDirection("slide-in-right");
      setCurrentPage(currentPage + 1);
    }
    setTimeout(() => setAnimationDirection(""), 500);
  };

  const handlePrevSlide = () => {
    if (currentPage > 1) {
      setAnimationDirection("slide-in-left");
      setCurrentPage(currentPage - 1);
    }
    setTimeout(() => setAnimationDirection(""), 500);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startItem = (currentPage - 1) * visibleRecords + 1;
  const endItem = Math.min(currentPage * visibleRecords, totalRecords);

  return (
    <Box sx={{ maxWidth: 480, margin: "auto", p: 0 }}>
      <Typography variant="h6" gutterBottom className="vendorHeading">
        Vendor Performance
      </Typography>
      <Box className="vendorMainWrap">
        {/* Arrow Buttons */}
        <div className="arrowButtons">
          <IconButton onClick={handlePrevSlide} disabled={currentPage === 1}>
            <ArrowBackIos className="arrowPrev" />
          </IconButton>
          <IconButton onClick={handleNextSlide} disabled={currentPage === totalPages}>
            <ArrowForwardIos />
          </IconButton>
        </div>

        {/* Card Display */}
        <Box
          sx={{
            display: "flex",
            overflow: "hidden",
            flexGrow: 1,
            gap: 2,
            position: "relative",
          }}
          className="cardContentBoxWrap"
        >
          {pageData.map((vendor) => (
            <Card
              key={vendor.id}
              sx={{ minWidth: "100%" }}
              className={`cardContentWrap ${animationDirection}`}
            >
              <CardContent className="cardContent">
                <Typography variant="subtitle1" className="vendorTitle">
                  {vendor.name}
                </Typography>
                <div className="insideVendorBoxWrap">
                  <Typography className="vendorDetailBox">
                    Received <span>{vendor.loads}</span>
                  </Typography>
                  <Typography className="vendorDetailBox">
                    Responded <span>{vendor.responded}</span>
                  </Typography>
                  <Typography className="vendorDetailBox">
                    Awarded <span>{vendor.awarded}</span>
                  </Typography>
                  <Typography className="vendorDetailBox">
                    Vehicles <span>{vendor.vehicle}</span>
                  </Typography>
                  <Typography className="vendorDetailBox">
                    Amount <span>{numeral(vendor.amount).format("0,0")}</span>
                  </Typography>
                </div>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>

      {/* Pagination Summary and Controls */}
      <Box
        className="paginationWrapper"
      >
        <Box
          className="paginationSummary"
        >
          {`${startItem} - ${endItem} of ${totalRecords} Items`}
        </Box>

        {/* Pagination */}
        {/* <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              className="prevNextButtons"
              components={{
                previous: () => <span className="prevData">Previous</span>,
                next: () => <span className="nextData">Next</span>,
              }}
            />
          )}
          classes={{ ul: "customPaginationUl" }}
        /> */}
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          color="primary"
          renderItem={(item) => {
            if (item.type === "start-ellipsis" || item.type === "end-ellipsis") {
              return <PaginationItem {...item} disabled />;
            }
            return (
              <PaginationItem
                {...item}
                className="prevNextButtons"
                components={{
                  previous: () => <span className="prevData">Previous</span>,
                  next: () => <span className="nextData">Next</span>,
                }}
              />
            );
          }}
          siblingCount={0} // No siblings around the current page
          boundaryCount={1} // Only the first and last page numbers as boundaries
          classes={{ ul: "customPaginationUl" }}
        />
      </Box>
    </Box>
  );
};










