import React, { useEffect, useState } from "react";
import { IContractDetailRecipientPageProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { Accordion, AccordionSummary, Grid, AccordionDetails, Button } from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DrawerAction, LoadAction } from "@temp/store/actions";
import { handleDateValidation, preventNonNumericalInput, showMessage, validateNameNumber } from "@temp/utils/helper";
import { RecipientStatus } from "../components/RecipientStatus";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import OutlinedInput from "@mui/material//OutlinedInput";
import InfoIcon from "@mui/icons-material/Info";
import { getDate, getTime } from "@temp/utils/helper";
import {
  PaymentTerms,
  AdditionalDetail,
  UploadedDocuments,
  Poster,
  TwoColumnRTLContractVehicle,
  TwoColumnRTLContractWeight,
} from "../components";
import { DocumentsTable, SelectionModal, InputModal, NewInput, PhoneInput, SimpleCheckBox } from "@components";
import {
  CustomText,
  VehicleIncDec,
  PrimaryButton,
  TitleLayout,
  MaterialUIPickers,
  OutLineButton
} from "@components";
import { LOAD_TYPE } from "@temp/constants";
import { ProfileAction } from "@temp/store/actions";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";

import {
  SimpleInput,
} from "@temp/components";
import { ExpandMore } from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import dayjs from "dayjs";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";


var numeral = require("numeral");
const editable_data = {
  total_freight_tons: null,
  pickup_date: "",
  pickup_time: "",
  deadline_date: "",
  deadline_time: "",
  delivery_date: "",
  delivery_time: "",
  emptyReturn_date: "",
  emptyReturn_time: "",
  title: "",
  ref_no: "",
  pickup_des: "",
  dropof_des: "",
  pickup_contact_name: "",
  dropoff_contact_name: "",
  pickup_contact_phone_no: "",
  dropoff_contact_phone_no: "",
  empty_return_yard_name: "",
  is_empty_return: false,
  contract_name: ""
};

let clickHoldTimerInc = null
let clickHoldTimerDec = null
const Page: React.FC<IContractDetailRecipientPageProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const params = useParams();


  const [load_data, set_load_data] = useState(editable_data);

  const [value, set_value] = useState('')
  const [valueDrop, set_value_drop] = useState('')

  useEffect(() => {
    set_value(load_data.pickup_contact_phone_no || '');
    set_value_drop(load_data.dropoff_contact_phone_no || '')
  }, [load_data]);

  const [expand, setExpand] = useState(true);
  const loadDetail = state?.data
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [showModalMedia, setShowModalMedia] = useState(false);
  const [temp, setTemp] = useState("");
  const [otherType, setOtherType] = React.useState("");
  const [docuentNameModal, setDocumentNameModal] = useState(false);
  const [selectedVehicle, setSelectedVehicles] = useState([])
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);
  const [dataDrop, setDataDrop] = useState(null);
  const [searchDrop, setSearchDrop] = useState("");
  const [showDateError, setShowDateError] = useState(null)
  const [hello, setHello] = useState([])
  const [uploaded_documents, set_uploaded_documents] = useState({
    bill_of_ladings: [],
    packing_lists: [],
    other_uploads: []
  })

  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const [dropoff_accordion, set_dropoff_accordion] = useState(false)

  const [pickup_accordion, set_pickup_accordion] = useState(false)

  useEffect(() => {
    if (loadDetail) {
      let contract_loc = loadDetail["contract_locations"][0];

      let is_category =
        loadDetail?.contract_locations[0]?.categories?.length;
      let obj = {
        total_freight_tons: !is_category
          ? contract_loc?.recipients[0]?.bid?.awarded_freight_ton
          : null,
        pickup_date: null,
        pickup_time: null,
        deadline_date: dayjs(new Date()).format("YYYY-MM-DD"),
        deadline_time: null,
        delivery_date: null,
        delivery_time: null,
        emptyReturn_date: null,
        emptyReturn_time: null,
        contract_name: loadDetail?.contract_name,
        title: loadDetail?.title,
        ref_no: loadDetail?.ref_no,
        pickup_des: contract_loc?.pickup?.description,
        dropof_des: contract_loc?.dropoff?.description,
        pickup_contact_name: contract_loc?.pickup?.contact_name,
        dropoff_contact_name: contract_loc?.dropoff?.contact_name,
        pickup_contact_phone_no: contract_loc?.pickup?.contact_phone_no,
        dropoff_contact_phone_no: contract_loc?.dropoff?.contact_phone_no,
      };
      set_load_data(obj);
    }
  }, [loadDetail]);


  useEffect(() => {
    setData({
      lat: loadDetail?.contract_locations[0].pickup.lat,
      long: loadDetail?.contract_locations[0].pickup.long,
      raw_address: loadDetail?.contract_locations[0].pickup.raw_address,
      title: loadDetail?.contract_locations[0].pickup.title,
      city: loadDetail?.contract_locations[0].pickup.city
    })
    setDataDrop({
      lat: loadDetail?.contract_locations[0].dropoff.lat,
      long: loadDetail?.contract_locations[0].dropoff.long,
      raw_address: loadDetail?.contract_locations[0].dropoff.raw_address,
      title: loadDetail?.contract_locations[0].dropoff.title,
      city: loadDetail?.contract_locations[0].dropoff.city

    })
    let cats = state?.data?.contract_locations[0]?.categories;
    let data = []
    cats?.forEach(element => {

      data.push({
        job_load_category_id: element?.id,
        job_load_recipient_id: element?.new_recipients?.id,
        no_of_vehicles: 0,
        total_freight_ton: element?.is_unit_basis ? element?.total_freight_ton : 0,
        price_unit: !state?.data?.is_unit_basis && state?.data?.quote_by == "weight_volume" ? element?.price_unit : null
      })
    });
    setSelectedVehicles(data)
  }, [])

  const callBack = () => {
    history.push("/dashboard/spot-posted");
  };

  const ConfirmLoadScreen = () => {
    let is_category = loadDetail?.quote_by == "vehicle";

    let deliveryFullDate;
    if (load_data?.delivery_date && load_data?.delivery_time) {

      deliveryFullDate = ` ${moment(load_data?.delivery_date).format("YYYY-MM-DD")} ${typeof (load_data?.delivery_time) === 'string' ? load_data?.delivery_time :
        moment(new Date(load_data?.delivery_time)).format('HH:mm:ss')}`

    }
    else if (load_data?.delivery_date) {

      deliveryFullDate = moment(load_data?.delivery_date).format("YYYY-MM-DD hh:mm:ss")
    }

    let emptyFullDate;
    if (load_data?.emptyReturn_date && load_data?.emptyReturn_time) {
      emptyFullDate = ` ${moment(load_data?.emptyReturn_date).format("YYYY-MM-DD")} ${typeof (load_data?.emptyReturn_time) === 'string' ? load_data?.emptyReturn_time :
        moment(new Date(load_data?.emptyReturn_time)).format('HH:mm:ss')}`

    } else if (load_data?.emptyReturn_date) {
      emptyFullDate = moment(load_data?.emptyReturn_date).format("YYYY-MM-DD hh:mm:ss")
    }

    let payload = {
      contract_id: loadDetail?.id,
      contract_name: loadDetail?.contract_name,
      location_id: loadDetail?.contract_locations[0]?.pickup?.id,
      categories: loadDetail?.quote_by == "vehicle" || loadDetail?.is_unit_basis ? selectedVehicle : null,
      job_load_recipient_id: is_category ? null : loadDetail?.contract_locations[0]?.categories[0]?.new_recipients?.id,
      title: load_data?.title,
      ref_no: load_data?.ref_no,
      pickup_date: moment(load_data.pickup_date).format("YYYY-MM-DD"),
      pickup_time: load_data?.pickup_time ? typeof (load_data?.pickup_time) === 'string' ? load_data?.pickup_time : moment(new Date(load_data?.pickup_time)).format('HH:mm:ss') : null,

      delivery_at: deliveryFullDate,
      empty_return_deadline_at: emptyFullDate,
      deadline_at: ` ${moment(load_data.deadline_date).format("YYYY-MM-DD")} ${typeof (load_data?.deadline_time) === 'string' ? load_data?.deadline_time : moment(new Date(load_data?.deadline_time)).format('HH:mm:ss')}`,

      pickup: {
        description: load_data?.pickup_des,
        contact_name: load_data?.pickup_contact_name,
        contact_phone_code: "92",
        contact_phone_no: load_data?.pickup_contact_phone_no,
        id: loadDetail?.contract_locations[0]?.pickup?.id,
        raw_address: data?.raw_address,
        lat: data?.lat,
        long: data?.long,
        title: data?.title,
        city: data?.city
      },
      dropoff: {
        description: load_data?.dropof_des,
        contact_name: load_data.dropoff_contact_name,
        contact_phone_code: "92",
        contact_phone_no: load_data.dropoff_contact_phone_no,
        id: loadDetail?.contract_locations[0]?.dropoff?.id,
        raw_address: dataDrop?.raw_address,
        lat: dataDrop?.lat,
        long: dataDrop?.long,
        title: dataDrop?.title,
        city: dataDrop?.city


      },
      total_freight_ton: !is_category ? load_data?.total_freight_tons : null,
      cb: callBack,
      documents: uploaded_documents || null,
      empty_return_yard_name: load_data?.empty_return_yard_name,
      is_empty_return: load_data?.is_empty_return ? true : false,
    };
    // return
    dispatch(LoadAction.postRequestVehicle(payload));
  };

  // updated code for city pickup

  const getCity = (addressComponents) => {
    for (let i = 0; i < addressComponents.length; i++) {
      for (let j = 0; j < addressComponents[i].types.length; j++) {
        if (addressComponents[i].types[j] === "locality") {
          return addressComponents[i].long_name;
        }
      }
    }
    return null;
  };

  const handleAutoComplete = (e, data) => {
    setData({
      title: data?.title,
      lat: null,
      long: null,
      raw_address: e.target.value,
      city: null,
      model_name: 'user',
      type: 'pickup',
    });

    var element = document.getElementById("map-address") as HTMLInputElement;
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const new_address = document.querySelector(`#map-address`)?.value;
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        const coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        const lat = coordinates?.lat;
        const lng = coordinates?.lng;
        const raw_address = new_address;
        const city = getCity(place.address_components);

        setData({
          title: data?.title,
          lat,
          long: lng,
          raw_address,
          city,
          model_name: "user",
          type: "pickup",
        });
      });
    }
  };

  const handleAutoCompleteDrop = (e, data) => {
    setDataDrop({
      title: dataDrop?.title,
      lat: null,
      long: null,
      raw_address: e.target.value,
      city: null,
      model_name: 'user',
      type: "dropoff",
    });

    var element = document.getElementById("map-address-drop") as HTMLInputElement;
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const new_address = document.querySelector(`#map-address-drop`)?.value;
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        const coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        const lat = coordinates?.lat;
        const lng = coordinates?.lng;
        const raw_address = new_address;
        const city = getCity(place.address_components);

        setDataDrop({
          title: dataDrop?.title,
          lat,
          long: lng,
          raw_address,
          city,
          model_name: "user",
          type: "dropoff",
        });
      });
    }
  };

  const renderRTL = (key, val) => {
    return (
      <div className="details-Content">
        <div className="details-rtl">
          <CustomText.OpenSansRegular>{key}</CustomText.OpenSansRegular>
        </div>
        <div className="details-rtl">
          <CustomText.OpenSansSemiBold>{val}</CustomText.OpenSansSemiBold>
        </div>
      </div>
    );
  };

  const onClickMediaType = (values: any) => { setShowModalMedia(false) };

  const onClickDocumentType = (val: any) => {
    setSelectedDocumentType(val);
    window["myObj"] = val.key;

    if (val?.key === "other_uploads") {
    } else {
    }
    setShowModalMedia(true);
    setTemp("");
    setOtherType("");
    window["docTitle"] = ""
  };

  const handleDocumentDelete = (ind, arrayIndex, val) => {
    let documents = uploaded_documents;

    if (val?.files?.length === 1) {
      let other_uploads = documents.other_uploads.filter(
        (val: any, index: number) => index !== arrayIndex
      );
      documents.other_uploads = other_uploads;
    } else {
      let other_uploads = documents.other_uploads[arrayIndex].files.filter(
        (val: any, index: number) => index !== ind
      );
      documents.other_uploads[arrayIndex].files = other_uploads;
    }
    set_uploaded_documents(documents)
    setHello([])
  };

  const handleUploadImage = (event: any, value: number) => {
    const formData = new FormData();
    formData.append("file", event?.target?.files[0]);
    setShowModalMedia(false);
    // return
    var cb = (payload: any) => {
      let documents = uploaded_documents
      if (Number(value.key) == 1) {
        setShowModalMedia(false);
      } else {
        if (payload?.mime_type) {
          setShowModalMedia(false);
        } else {
          showMessage.current({
            message: "Please upload File.",
            status: "error",
          });
          return;
        }
      }
      if (selectedDocumentType.key === "bill_of_ladings") {
        documents.bill_of_ladings = [...documents.bill_of_ladings, payload];
      } else if (selectedDocumentType.key === "packing_lists") {
        documents.packing_lists = [...documents.packing_lists, payload];
      } else {
        let isNameAlreadyExist = documents.other_uploads.find(
          (val: any) => val.title === otherType
        );

        if (isNameAlreadyExist) {
          let indexOfArray = documents.other_uploads.findIndex(
            (val: any) => val.title === otherType
          );
          documents.other_uploads[indexOfArray].files = [
            payload,
            ...documents.other_uploads[indexOfArray].files,
          ];
        } else {
          documents.other_uploads = [
            ...documents.other_uploads,
            { title: otherType, files: [payload] },
          ];
        }
      }
      set_uploaded_documents(documents)
      setHello([])
    };
    dispatch(ProfileAction.uploadFile({ data: formData, cb }));
  };

  const handleCamrea = (data) => {
    if (sendEventToAndroid("accessCamera")) {
      window.Android.accessCamera(
        String("access")
      );
    } else if (sendEventToiOS("accessCamera")) {
      window.webkit.messageHandlers.accessCamera.postMessage(
        JSON.stringify({
          access: "access",
        })
      );
    } else {
    }
  }

  const handleDoc = (data) => {
    if (sendEventToAndroid("accessDoc")) {
      window.Android.accessDoc(
        String("access-doc")
      );
    } else if (sendEventToiOS("accessDoc")) {
      window.webkit.messageHandlers.accessDoc.postMessage(
        JSON.stringify({
          access: "access-doc",
        })
      );
    } else {
    }
  }

  const handleGallery = (data) => {
    if (sendEventToAndroid("accessGallery")) {
      window.Android.accessGallery(
        String("access-gallery")
      );
    } else if (sendEventToiOS("accessGallery")) {
      window.webkit.messageHandlers.accessGallery.postMessage(
        JSON.stringify({
          access: "access-gallery",
        })
      );
    } else {
    }
  }

  const callBack2 = (payload) => {

    let new_payload = {
      file_name: payload?.fileName,
      cloudinary_url: payload?.cloudinaryUrl,
      file_url: payload?.fileUrl,
      mime_type: payload?.mimeType
    }
    let documents = uploaded_documents
    setShowModalMedia(false);


    if (window?.myObj === "bill_of_ladings") {
      if (!documents["bill_of_ladings"]?.find(item => item?.file_url == new_payload?.file_url)) {
        documents["bill_of_ladings"].push(new_payload)
      }
    } else if (window?.myObj === "packing_lists") {
      if ((!documents["packing_lists"]?.find(item => item?.file_url == new_payload?.file_url))) {
        documents["packing_lists"].push(new_payload)
      }
    } else if (window?.myObj === "other_uploads") {
      let isNameAlreadyExist = documents.other_uploads.find(
        (val: any) => val.title === window?.docTitle
      );

      if (isNameAlreadyExist) {
        let indexOfArray = documents.other_uploads.findIndex(
          (val: any) => val.title === window?.docTitle
        );
        documents["other_uploads"][indexOfArray].files = [
          ...documents.other_uploads[indexOfArray].files,
        ];
        if (!documents["other_uploads"][indexOfArray]?.files.find(item => item?.file_url == new_payload?.file_url)) {
          documents["other_uploads"][indexOfArray].files.push(new_payload)
        }
      } else {
        documents["other_uploads"] = [...documents.other_uploads];
        let files_arr = []
        if (!files_arr.find(item => item?.file_url == new_payload?.file_url)) {
          files_arr.push(new_payload)
        }

        let obj = {
          title: window?.docTitle, files: files_arr
        }
        documents["other_uploads"].push(obj)
      }
    }
    set_uploaded_documents(documents)
    setHello([])
    new_payload = null
  }


  useEffect(() => {
    const target = message => {
      callBack2(JSON.parse(message?.detail))
    }
    window.addEventListener("AudioUploadsuccess", target);
    return () => {
      window["myObj"] = ""
      window.removeEventListener("AudioUploadsuccess", (e) => { })
    }
  }, [])

  const onAddOtherDocumentType = (key: any) => {
    setOtherType(temp);
    window["docTitle"] = temp;
    setDocumentNameModal(false);
    setShowModalMedia(true);
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleDisable = () => {


    let error_result =
      (showDateError !== "" ||
        !load_data?.pickup_date ||
        !load_data?.deadline_date ||
        !load_data?.deadline_time ||
        !load_data?.pickup_contact_name ||
        !data?.raw_address || !dataDrop?.raw_address ||
        !data?.title || !dataDrop?.title ||
        load_data?.pickup_contact_phone_no?.length < 10 ||
        !load_data?.dropoff_contact_name ||
        load_data?.dropoff_contact_phone_no?.length < 10
        || !load_data?.title
      ) ? false : true

    let error_vehicle = selectedVehicle.every(item => item?.no_of_vehicles > 0)

    return loadDetail?.quote_by === "vehicle" || loadDetail?.is_unit_basis ? (error_result && error_vehicle ? false : true) : (error_result && load_data?.total_freight_tons ? false : true)
  }

  if (loadDetail) {
    return (
      <TitleLayout titleOnly titleOnlyText={`Confirm Load`}>
        <div className="loadDetailWrapper">
          <div className="inputField">
            <div className="label">Contract Name</div>
            <div className="field">
              <SimpleInput
                onInput={(e) => {
                  let new_data = {
                    ...load_data,
                    contract_name: e.target.value,
                  };
                  set_load_data(new_data);
                }}
                disabled={true}
                id="gross_weight"
                name="gross_weight"
                value={load_data?.contract_name}
                type="text"
                placeholder={""}
              />

            </div>
          </div>
          <div className="inputField">
            <div className="label">Load Name</div>
            <div className="field">
              <SimpleInput
                onChange={(e) => {
                  let new_data = {
                    ...load_data,
                    title: e.target.value,
                  };
                  set_load_data(new_data);
                }}
                value={load_data?.title ? load_data?.title : ""}
                id="load_name"
                name="load_name"
                type="text"
                placeholder={" "}
              />
            </div>
          </div>
          <div className="inputField">
            <div className="label">Contract Reference</div>
            <div className="field">
              <SimpleInput
                onChange={(e) => {
                  let new_data = {
                    ...load_data,
                    ref_no: e.target.value,
                  };
                  set_load_data(new_data);
                }}
                value={load_data?.ref_no ? load_data?.ref_no : ""}
                id="ref_no"
                name="ref_no"
                type="text"
                placeholder={" "}
              />
            </div>
          </div>

          <div>
            {/* dates container  */}
            <div>
              {/* response deadline */}
              <div>
                <CustomText.OpenSansSemiBold
                  textStyle={{ fontSize: "0.875rem", marginTop: 15 }}
                >
                  Acceptance Deadline
                </CustomText.OpenSansSemiBold>
                <div className="DatePickerFieldWrap">
                  <MaterialUIPickers
                    setDate={(e) => {
                      let new_date = { ...load_data, deadline_date: e };
                      set_load_data(new_date);
                      setShowDateError(handleDateValidation(e, load_data?.deadline_time, load_data?.pickup_date, load_data?.pickup_time, load_data?.delivery_date, load_data?.delivery_time, load_data?.emptyReturn_date, load_data?.emptyReturn_time, 'request_vehicle_screen'))
                      // 
                    }}
                    setTime={(e) => {
                      let new_date = { ...load_data, deadline_time: e };
                      set_load_data(new_date);
                      setShowDateError(handleDateValidation(load_data?.deadline_date, e, load_data?.pickup_date, load_data?.pickup_time, load_data?.delivery_date, load_data?.delivery_time, load_data?.emptyReturn_date, load_data?.emptyReturn_time, 'request_vehicle_screen'))
                    }}
                    date={load_data?.deadline_date}
                    time={load_data?.deadline_time}
                    from={true}
                    maxDate={load_data?.pickup_date ? load_data?.pickup_date : "3050-01-01"}
                  />
                </div>
              </div>

              {/* pickup deadline */}
              <div>
                <CustomText.OpenSansSemiBold
                  textStyle={{ fontSize: "0.875rem", marginTop: 15 }}
                >
                  Pick Up Date
                </CustomText.OpenSansSemiBold>
                <div className="DatePickerFieldWrap">
                  <MaterialUIPickers
                    setDate={(e) => {
                      let new_date = { ...load_data, pickup_date: e };
                      set_load_data(new_date);
                      setShowDateError(handleDateValidation(load_data?.deadline_date, load_data.deadline_time, e, load_data?.pickup_time, load_data?.delivery_date, load_data?.delivery_time, load_data?.emptyReturn_date, load_data?.emptyReturn_time, 'request_vehicle_screen'))
                    }}
                    from={true}
                    fromTime={true}
                    setTime={(e, i) => {
                      let new_date = { ...load_data, pickup_time: e };
                      set_load_data(new_date);
                      setShowDateError(handleDateValidation(load_data?.deadline_date, load_data.deadline_time, load_data?.pickup_date, e, load_data?.delivery_date, load_data?.delivery_time, load_data?.emptyReturn_date, load_data?.emptyReturn_time, 'request_vehicle_screen'))
                    }}
                    date={load_data.pickup_date}
                    time={load_data?.pickup_time}
                    minDate={load_data.deadline_date}
                    maxDate={"3050-01-01"}
                  />
                </div>
              </div>

              {/* delivery deadline */}
              <div>
                <CustomText.OpenSansSemiBold
                  textStyle={{ fontSize: "0.875rem", marginTop: 15 }}
                >
                  Delivery Deadline
                </CustomText.OpenSansSemiBold>
                <div className="DatePickerFieldWrap">
                  <MaterialUIPickers
                    setDate={(e) => {
                      let new_date = { ...load_data, delivery_date: e };
                      set_load_data(new_date);
                      setShowDateError(handleDateValidation(load_data?.deadline_date, load_data.deadline_time, load_data?.pickup_date, load_data?.pickup_time, e, load_data?.delivery_time, load_data?.emptyReturn_date, load_data?.emptyReturn_time, 'request_vehicle_screen'))
                    }}
                    from={false}
                    fromTime={true}
                    setTime={(e, i) => {
                      let new_date = { ...load_data, delivery_time: e };
                      set_load_data(new_date);
                      setShowDateError(handleDateValidation(load_data?.deadline_date, load_data.deadline_time, load_data?.pickup_date, load_data?.pickup_time, load_data?.delivery_date, e, load_data?.emptyReturn_date, load_data?.emptyReturn_time, 'request_vehicle_screen'))
                    }}
                    date={load_data.delivery_date}
                    time={load_data?.delivery_time}
                    minDate={load_data.pickup_date}
                    maxDate={"3050-01-01"}
                  />
                </div>
              </div>

              {
                state?.data?.load_type?.title === LOAD_TYPE.fcl && (
                  <div>
                    <SimpleCheckBox
                      checked={load_data?.is_empty_return}
                      handleChange={(e) => {
                        let new_data = { ...load_data, is_empty_return: e.target.checked, empty_return_yard_name: e.target.checked ? load_data?.empty_return_yard_name : '' };
                        set_load_data(new_data);
                      }}
                      labelName={"Empty Pickup or Return Yard / City"}
                      title={"Empty Pickup or Return Yard / City"}
                    />
                    {
                      load_data?.is_empty_return && (
                        <div className="inputWrapper">
                          <div className="inputField">
                            <div className="label">
                              Name of Yard/City
                            </div>
                            <div className="field">
                              <SimpleInput
                                isPKR={false}
                                value={load_data?.empty_return_yard_name}
                                placeholder="Enter Name of Yard/City"
                                onChange={(e) => {
                                  let new_date = { ...load_data, empty_return_yard_name: e.target.value };
                                  set_load_data(new_date);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                )
              }

              {/* Empty return deadline  */}
              <div>
                {loadDetail?.load_type?.title == LOAD_TYPE.fcl && load_data?.is_empty_return ?
                  <>
                    <CustomText.OpenSansSemiBold
                      textStyle={{ fontSize: "0.875rem", marginTop: 15 }}
                    >
                      Empty Pickup / Return Deadline
                    </CustomText.OpenSansSemiBold>
                    <div className="DatePickerFieldWrap">
                      <MaterialUIPickers
                        setDate={(e) => {
                          let new_date = { ...load_data, emptyReturn_date: e };
                          set_load_data(new_date);
                          setShowDateError(handleDateValidation(load_data?.deadline_date, load_data.deadline_time, load_data?.pickup_date, load_data?.pickup_time, load_data?.delivery_date, load_data.delivery_time, e, load_data?.emptyReturn_time, 'request_vehicle_screen'))
                        }}
                        from={false}
                        fromTime={true}
                        setTime={(e, i) => {
                          let new_date = { ...load_data, emptyReturn_time: e };
                          set_load_data(new_date);
                          setShowDateError(handleDateValidation(load_data?.deadline_date, load_data.deadline_time, load_data?.pickup_date, load_data?.pickup_time, load_data?.delivery_date, load_data.delivery_time, load_data?.emptyReturn_date, e, 'request_vehicle_screen'))
                        }}
                        date={load_data.emptyReturn_date}
                        time={load_data?.emptyReturn_time}
                        minDate={new Date()}
                        maxDate={"3050-01-01"}
                      />

                    </div>

                  </>
                  : null}
              </div>
            </div>

            {/* dates error message  */}
            <div>
              {
                showDateError ?
                  <div className="error-container error">
                    <div>
                      <InfoIcon className="error-icon_error" />
                    </div>
                    <div className="error-text">
                      {showDateError}
                    </div>
                  </div> : null
              }

            </div>
          </div>

          <Poster
            loadDetail={loadDetail}
            expand={expand}
            setExpand={setExpand}
            showMessage={false}
            category={loadDetail?.contract_locations[0]?.categories}
          />

          {/*contract  detail */}
          <div className="accordion-container">
            <Accordion className="accordion-background">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{
                    fontSize: "0.875rem",
                    color: Colors.Black,
                  }}
                >
                  Contract Details
                </CustomText.OpenSansSemiBold>
              </AccordionSummary>
              <div className="accordion-content">
                {[
                  renderRTL(
                    "Contract Name",
                    `${loadDetail?.contract_name}`
                  ),
                  renderRTL(
                    "Contract Start",
                    `${moment(loadDetail?.contract_start_date).format(
                      "DD MMM, YYYY hh:mm A"
                    )}`
                  ),
                  renderRTL(
                    "Contract End",
                    `${moment(loadDetail.contract_end_date).format(
                      "DD MMM, YYYY hh:mm A"
                    )}`
                  ),
                  renderRTL(
                    "Remarks",
                    `${loadDetail?.remarks || "--"} `
                  ),
                ]}
                <div style={{ height: 16 }}></div>
              </div>
            </Accordion>
          </div>

          {/* Payment Terms */}
          <PaymentTerms loadDetail={loadDetail} />

          {/* additional detail */}
          <AdditionalDetail loadDetail={loadDetail} fromPoster={false} />

          <div>
            {loadDetail?.contract_locations?.length > 0 ? (
              <div>
                {loadDetail?.contract_locations?.map((location, index) => {
                  return (
                    <div>
                      <div className="accordion-container">
                        <Accordion
                          onChange={() => setExpand(!expand)}
                          expanded={expand}
                          className="accordion-background"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <CustomText.OpenSansSemiBold
                              textStyle={{
                                fontSize: "0.875rem",
                                color: Colors.Black,
                              }}
                            >
                              Location {` ${index + 1}`}
                            </CustomText.OpenSansSemiBold>
                          </AccordionSummary>
                          <div className="accordion-content">
                            <div className="load-detail-location-container">
                              <div style={{ width: "100%" }}>
                                <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                                  {/* pickup  */}
                                  <div style={{ marginTop: 5 }}>
                                    <p className="titleSection">Pickup Location</p>
                                    <div>
                                      <div style={{ width: "100%" }}>
                                        <div className="inputField">
                                          <div className="field">
                                            <div className={`input-container-new ${data?.title ? 'filled' : ''}`}>
                                              <input
                                                className="new-inut-field"
                                                onChange={(e) => {
                                                  setData({
                                                    ...data,
                                                    lat: null,
                                                    lng: null,
                                                    title: e.target.value,
                                                  });
                                                }}
                                                type="text"
                                                autoComplete="off"
                                                name="search"
                                                value={data?.title}
                                                placeholder="Enter your pickup location name"
                                                maxLength={30}

                                              />
                                              <span class="inputSlide"></span>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="inputField">
                                          <div className="field customMapField customMapFieldNew newPadAdd">
                                            <input
                                              id="map-address"
                                              onChange={(e) => {
                                                handleAutoComplete(e, data)
                                              }}
                                              type="text"
                                              autoComplete="off"
                                              name="search"
                                              value={data?.raw_address}
                                              placeholder="Enter detailed address"
                                              maxLength={100}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {
                                        [...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length ?
                                          <div style={{ padding: 0, paddingTop: 15 }} className="sectionBox">
                                            <div className="addressAccordion" style={{ width: "100%", overflowY: "scroll", marginBottom: 10 }} >
                                              <Accordion
                                                expanded={pickup_accordion}
                                                onChange={() => set_pickup_accordion(!pickup_accordion)}
                                              >
                                                <AccordionSummary
                                                  expandIcon={<ExpandMore />}
                                                  aria-controls="panel1a-content"
                                                  id="panel-header"
                                                >
                                                  <label className="fw-5">Select From Saved Addresses </label>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <div className={`faqas-item`} style={{ width: '100%' }}>
                                                    <>
                                                      {
                                                        [...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length > 1 ?
                                                          <div className="searchContainer" style={{ width: "100%" }}>
                                                            <input
                                                              className="searchInput"
                                                              onChange={(e) => setSearch(e.target.value)}
                                                              type="text"
                                                              name="search"
                                                              value={search}
                                                              placeholder="Search by name"
                                                              style={{ width: "100%" }}
                                                            />
                                                            <span className={search ? 'icons-cross' : 'icons-search'} onClick={() => {
                                                              setSearch("");
                                                            }}></span>
                                                          </div> : null
                                                      }

                                                      <div >
                                                        {[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].filter((item) =>
                                                          item?.title?.toLowerCase()
                                                            .includes(search.toLowerCase())

                                                        )?.sort((a, b) => {
                                                          if (a?.title?.toLowerCase().indexOf(search.toLowerCase()) > b?.title?.toLowerCase().indexOf(search.toLowerCase())) {
                                                            return 1;
                                                          } else if (a?.title?.toLowerCase().indexOf(search.toLowerCase()) < b?.title?.toLowerCase().indexOf(search.toLowerCase())) {
                                                            return -1;
                                                          } else {
                                                            if (a.title > b.title)
                                                              return 1;
                                                            else
                                                              return -1;
                                                          }
                                                        })?.map((val) => {

                                                          return (
                                                            <Button
                                                              onClick={() => {
                                                                const { raw_address, long, lat, title, description, contact_name, contact_phone_no, city } = val;
                                                                let obj = { ...load_data, pickup_contact_name: contact_name, pickup_contact_phone_no: contact_phone_no }

                                                                set_load_data(obj)
                                                                setData({
                                                                  raw_address,
                                                                  lat: lat,
                                                                  long: long,
                                                                  title: title,
                                                                  timestamp: null,
                                                                  description: description || "",
                                                                  city: city
                                                                });
                                                                set_pickup_accordion(!pickup_accordion)
                                                                setTimeout(() => {
                                                                  setHello([])
                                                                }, 100);
                                                              }}
                                                              className="locationItem"
                                                            >
                                                              <RoomIcon className="icon" />
                                                              <label className="title">
                                                                {val?.title || ""}
                                                              </label>
                                                            </Button>
                                                          )
                                                        })
                                                        }
                                                      </div>
                                                    </>
                                                  </div>
                                                </AccordionDetails>
                                              </Accordion>
                                            </div>
                                          </div> : null
                                      }
                                      <OutLineButton
                                        type={"submit"}
                                        title="Add to address book"
                                        buttonStyle={{ marginBottom: "1rem", }}
                                        disabled={
                                          ![...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff]?.find(item => item?.raw_address == data?.raw_address && item?.title == data?.title)?.id && data?.raw_address && data?.title
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          dispatch(
                                            DrawerAction.addLocation({
                                              data: {
                                                ...data, type: 'pickup', raw_address: data?.raw_address, model_name: 'user',

                                              },
                                            })
                                          );
                                        }
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* dropofff */}
                                  <div style={{ marginTop: 20 }}>
                                    <p className="titleSection">Drop Off Location</p>
                                    <div >
                                      <div className="inputField">
                                        <div className="field">
                                          <div className={`input-container-new ${dataDrop?.title ? 'filled' : ''}`}>
                                            <input
                                              className="new-inut-field"
                                              onChange={(e) => {
                                                setDataDrop({
                                                  ...dataDrop,
                                                  lat: null,
                                                  lng: null,
                                                  title: e.target.value,
                                                });
                                              }}
                                              type="text"
                                              autoComplete="off"
                                              name="search"
                                              style={{ width: "100%" }}
                                              value={dataDrop?.title}
                                              placeholder="Enter your dropoff location name"
                                              maxLength={30}
                                            />
                                            <span class="inputSlide"></span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="inputField">
                                        <div className="field customMapField customMapFieldNew newPadAdd ">
                                          <input
                                            id="map-address-drop"
                                            // onChange={handleAutoCompleteDrop}
                                            onChange={(e) => {
                                              handleAutoCompleteDrop(e, dataDrop)
                                            }}
                                            type="text"
                                            autoComplete="off"
                                            name="search"
                                            // value={searchDrop}
                                            value={dataDrop?.raw_address}
                                            placeholder="Enter detailed address"
                                            maxLength={100}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {
                                        [...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length ?
                                          <div style={{ padding: 0, paddingTop: 15 }} className="sectionBox">
                                            <div className="addressAccordion" style={{ width: "100%", overflowY: "scroll", marginBottom: 10 }} >
                                              <Accordion
                                                expanded={dropoff_accordion}
                                                onChange={() => set_dropoff_accordion(!dropoff_accordion)}
                                              >
                                                <AccordionSummary
                                                  expandIcon={<ExpandMore />}
                                                  aria-controls="panel1a-content"
                                                  id="panel-header"
                                                >
                                                  <label className="fw-5">Select From Saved Addresses </label>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <div className={`faqas-item`} style={{ width: '100%' }}>
                                                    <>
                                                      {
                                                        [...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length > 1 ?
                                                          <div className="searchContainer" style={{ marginBottom: '1rem' }} >
                                                            <input
                                                              className="searchInput"
                                                              onChange={(e) => setSearchDrop(e.target.value)}
                                                              type="text"
                                                              name="search"
                                                              value={searchDrop}
                                                              placeholder="Search by name"
                                                            />
                                                            <span onClick={() => {
                                                              setSearchDrop("");
                                                            }}></span>
                                                          </div> : null
                                                      }
                                                      <div >
                                                        {[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].filter((item) =>
                                                          item?.title?.toLowerCase()
                                                            .includes(searchDrop.toLowerCase())

                                                        )?.sort((a, b) => {
                                                          if (a?.title?.toLowerCase().indexOf(searchDrop.toLowerCase()) > b?.title?.toLowerCase().indexOf(searchDrop.toLowerCase())) {
                                                            return 1;
                                                          } else if (a?.title?.toLowerCase().indexOf(searchDrop.toLowerCase()) < b?.title?.toLowerCase().indexOf(searchDrop.toLowerCase())) {
                                                            return -1;
                                                          } else {
                                                            if (a.title > b.title)
                                                              return 1;
                                                            else
                                                              return -1;
                                                          }
                                                        })?.map((val) => {
                                                          return (
                                                            <Button
                                                              onClick={() => {
                                                                const { raw_address, long, lat, title, description, contact_name, contact_phone_no, city } = val;
                                                                let obj = { ...load_data, dropoff_contact_name: contact_name, dropoff_contact_phone_no: contact_phone_no }

                                                                set_load_data(obj)
                                                                setDataDrop({
                                                                  raw_address,
                                                                  lat: lat,
                                                                  long: long,
                                                                  title: title,
                                                                  timestamp: null,
                                                                  description: description || "",
                                                                  contact_name: contact_name,
                                                                  contact_phone_no: contact_phone_no,
                                                                  contact_phone_code: '92',
                                                                  city: city
                                                                });
                                                                set_dropoff_accordion(!dropoff_accordion)
                                                                setTimeout(() => {
                                                                  setHello([])
                                                                }, 100);
                                                              }}
                                                              className="locationItem"
                                                            >
                                                              <RoomIcon className="icon" />
                                                              <label className="title">
                                                                {val?.title || ""}
                                                              </label>
                                                            </Button>
                                                          )
                                                        })
                                                        }
                                                      </div>
                                                    </>
                                                  </div>
                                                </AccordionDetails>
                                              </Accordion>
                                            </div>
                                          </div> : null
                                      }
                                      <OutLineButton
                                        type={"submit"}
                                        title="Add to address book"
                                        buttonStyle={{ marginBottom: "1rem", }}
                                        disabled={
                                          ![...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff]?.find(item => item?.raw_address == dataDrop?.raw_address && item?.title == dataDrop?.title)?.id && dataDrop?.raw_address && dataDrop?.title
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          dispatch(
                                            DrawerAction.addLocation({
                                              data: { ...dataDrop, type: 'dropoff', raw_address: dataDrop?.raw_address, model_name: 'user' },
                                            })
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-content">
                            <div
                              style={{
                                paddingTop: 24,
                                paddingBottom: 16,
                              }}
                            >
                              <CustomText.OpenSansSemiBold
                                textStyle={{
                                  fontSize: "0.875rem",
                                  fontWeight: "600"
                                }}
                              >
                                Contact at Pickup
                              </CustomText.OpenSansSemiBold>
                              <div className="inputField mtop8">
                                <div className="label">Name <span className="redCol">*</span></div>
                                <div className="field">
                                  <SimpleInput
                                    type="text"
                                    value={load_data.pickup_contact_name ? load_data?.pickup_contact_name : ""}
                                    onChange={(e) => {
                                      let new_data = {
                                        ...load_data,
                                        pickup_contact_name: e.target.value,
                                      };
                                      set_load_data(new_data);
                                    }}
                                    placeholder="Enter your name"
                                  />
                                </div>
                              </div>
                              <div className="inputField driverNumberField">
                                <div className="label">Phone Number <span className="redCol">*</span></div>
                                <div className="field">
                                  {/* <PhoneInput
                                    value={load_data?.pickup_contact_phone_no ? load_data?.pickup_contact_phone_no : ""}
                                    phoneCode={"92"}
                                    onChange={(e) => {
                                      if (e.target.value.length < 11) {
                                        let new_data = {
                                          ...load_data,
                                          pickup_contact_phone_no:
                                            e.target.value,
                                        };
                                        set_load_data(new_data);
                                      }
                                    }}
                                    id="phone-number"
                                    placeholder={"3000000000"}
                                  /> */}

                                  {/* new changes below */}
                                  <SimplePhoneInput
                                    value={value}
                                    onChange={(e) => {
                                      let newValue = e.target.value;
                                      // Remove non-digit characters (including hyphens)
                                      newValue = newValue.replace(/\D/g, '');
                                      // Remove leading zeros or the '92' prefix
                                      if (newValue.startsWith('92')) {
                                        newValue = newValue.slice(2); // Remove '92' from the start
                                      }
                                      newValue = newValue.replace(/^0+/, '');
                                      // Limit length to 10 digits
                                      newValue = newValue.slice(0, 10);
                                      // Update state
                                      set_value(newValue);
                                      // Update load_data if input length is less than 11
                                      if (newValue.length < 11) {
                                        set_load_data((prev) => ({
                                          ...prev,
                                          pickup_contact_phone_no: newValue,
                                        }));
                                      }
                                    }}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      let pasteData = e.clipboardData.getData('text');
                                      pasteData = pasteData.replace(/\D/g, '').slice(0, 10); // Clean up pasted data
                                      set_value(pasteData);
                                    }}
                                    placeholder="3000000000"
                                    id="phone-number"
                                  />

                                </div>
                              </div>
                            </div>

                            <div>
                              <CustomText.OpenSansSemiBold
                                textStyle={{
                                  fontSize: "0.875rem",
                                  fontWeight: "600"
                                }}
                              >
                                Contact at DropOff
                              </CustomText.OpenSansSemiBold>
                              <div className="inputField mtop8">
                                <div className="label">Name <span className="redCol">*</span></div>
                                <div className="field">
                                  <SimpleInput
                                    type="text"
                                    value={load_data?.dropoff_contact_name ? load_data?.dropoff_contact_name : ""}
                                    onChange={(e) => {
                                      let new_data = {
                                        ...load_data,
                                        dropoff_contact_name: e.target.value,
                                      };
                                      set_load_data(new_data);
                                    }}
                                    placeholder="Enter your name"
                                  />
                                </div>
                              </div>
                              <div className="inputField driverNumberField">
                                <div className="label">Phone Number <span className="redCol">*</span></div>
                                <div className="field">
                                  {/* <PhoneInput
                                    value={load_data?.dropoff_contact_phone_no ? load_data?.dropoff_contact_phone_no : ""}
                                    phoneCode={"92"}
                                    onChange={(e) => {
                                      if (e.target.value.length < 11) {
                                        let new_data = {
                                          ...load_data,
                                          dropoff_contact_phone_no:
                                            e.target.value,
                                        };
                                        set_load_data(new_data);
                                      }
                                    }}
                                    id="phone-number"
                                    placeholder={"3000000000"}
                                  /> */}
                                  
                                  {/* new changes below */}
                                  <SimplePhoneInput
                                    value={valueDrop}
                                    onChange={(e) => {
                                      let newValue = e.target.value;
                                      // Remove non-digit characters (including hyphens)
                                      newValue = newValue.replace(/\D/g, '');
                                      // Remove leading zeros or the '92' prefix
                                      if (newValue.startsWith('92')) {
                                        newValue = newValue.slice(2); // Remove '92' from the start
                                      }
                                      newValue = newValue.replace(/^0+/, '');
                                      // Limit length to 10 digits
                                      newValue = newValue.slice(0, 10);
                                      // Update state
                                      set_value(newValue);
                                      // Update load_data if input length is less than 11
                                      if (newValue.length < 11) {
                                        set_load_data((prev) => ({
                                          ...prev,
                                          dropoff_contact_phone_no: newValue,
                                        }));
                                      }
                                    }}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      let pasteData = e.clipboardData.getData('text');
                                      pasteData = pasteData.replace(/\D/g, '').slice(0, 10); // Clean up pasted data
                                      set_value(pasteData);
                                    }}
                                    placeholder="3000000000"
                                    id="phone-number"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style={{ marginTop: 10 }} className="accordion-content">
                            <>
                              {location?.categories?.map(
                                (category, index) => {
                                  let find = selectedVehicle.find(item => item?.job_load_category_id == category?.id)
                                  let find_index = selectedVehicle.findIndex(item => item?.job_load_category_id == category?.id)
                                  return (
                                    <div>
                                      <div className="category-title-container">
                                        <div className="customsemiBoldHeading">
                                          <CustomText.OpenSansSemiBold
                                          >
                                            {
                                              state?.data?.quote_by == "vehicle" && !state?.data?.is_unit_basis ?
                                                location?.categories?.length > 1 ? `Vehicle Category ${index + 1}` : "Vehicle Category"
                                                : !state?.data?.is_unit_basis ?
                                                  'Cargo detail' : null
                                            }
                                            {
                                              state?.data?.is_unit_basis ?
                                                location?.categories?.length > 1 ? `Item Category ${index + 1}` : "Item Category" : null
                                            }
                                          </CustomText.OpenSansSemiBold>

                                          {location?.categories?.length && (
                                            <div className="chip customChip">
                                              <span>
                                                {
                                                  state?.data?.quote_by == "vehicle" ?
                                                    `${category?.vehicle_quantity || "N/A"} Vehicles` :
                                                    state?.data?.is_unit_basis ? `${category?.unit_quantity}` :
                                                      `${location?.pickup?.attributes?.no_of_packages || "N/A"} Packages`
                                                }
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        {
                                          loadDetail?.quote_by == "vehicle" || loadDetail?.is_unit_basis ?
                                            <TwoColumnRTLContractVehicle
                                              loadDetail={loadDetail}
                                              category={category}
                                              location={location}
                                              requestVehicle={true}

                                            />
                                            : <TwoColumnRTLContractWeight
                                              loadDetail={loadDetail}
                                              location={location}
                                            />
                                        }

                                      </div>

                                      <div className="dividing-bordered"></div>
                                      <div className="detail-category-recepient-container">
                                        <div className="load-detail-user">
                                          <img
                                            alt="profile"
                                            className="load-detail-user"
                                            src={
                                              category?.recipients[0]
                                                ?.recipient?.picture ||
                                              Images.myProfileImage
                                            }
                                          />
                                        </div>
                                        <div className="recepient-detail-container">
                                          <div className="width-100">
                                            {/* top row  */}
                                            <div className="flexRow100">
                                              <div>
                                                <CustomText.OpenSansRegular>
                                                  {category?.new_recipients
                                                    ?.recipient?.name ||
                                                    "UnRegistered User"}
                                                </CustomText.OpenSansRegular>
                                              </div>
                                            </div>
                                            {/* bottom row  */}
                                            <div className="recepient-status-container">
                                              <div
                                                style={{
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                                className="flexRow"
                                              >
                                                <div className="chip">
                                                  {category?.recipients[0]
                                                    ?.bid?.price_per_freight
                                                    ? `${numeral(category?.recipients[0]?.bid?.price_per_freight).format("0,0.00")} PKR / ${category?.price_unit}`
                                                    :
                                                    category?.is_unit_basis ?

                                                      `${numeral(category?.recipients[0]?.bid?.bid_price_per_vehicle).format("0,0.00")} PKR / ${category?.is_unit_basis ? "Item" : "Vehicle"}`
                                                      :
                                                      `${numeral(category?.recipients[0]?.bid?.bid_price_per_vehicle).format("0,0")} PKR / ${category?.is_unit_basis ? "Item" : "Vehicle"}`



                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="dividing-bordered"></div>
                                      <CustomText.OpenSansSemiBold>
                                        Agreed Price (Without GST)
                                      </CustomText.OpenSansSemiBold>
                                      {
                                        loadDetail?.quote_by == "vehicle" || state?.data?.is_unit_basis ?
                                          <div>
                                            {renderRTL(
                                              `Price / ${state?.data?.is_unit_basis ? " Item" : "Vehicle"} (Without GST)`,
                                              state?.data?.is_unit_basis ?
                                                `${numeral(
                                                  category?.new_recipients?.bid
                                                    ?.bid_price_per_vehicle
                                                ).format("0,0.00")} PKR`
                                                :
                                                `${numeral(
                                                  category?.new_recipients?.bid
                                                    ?.bid_price_per_vehicle
                                                ).format("0,0")} PKR`
                                            )}

                                            {
                                              category?.new_recipients?.bid
                                                ?.bid_detention_rate &&
                                              renderRTL(
                                                "Detention Rate (Without GST)",
                                                state?.data?.is_unit_basis ?
                                                  `${numeral(
                                                    category?.new_recipients?.bid
                                                      ?.bid_detention_rate
                                                  ).format("0,0.00")} PKR` :
                                                  `${numeral(
                                                    category?.new_recipients?.bid
                                                      ?.bid_detention_rate
                                                  ).format("0,0")} PKR`

                                              )}

                                            {category?.is_twin_load && loadDetail?.load_type?.title == LOAD_TYPE.fcl ?
                                              renderRTL(
                                                `Price / Container (Without GST)`,
                                                `${numeral(Number
                                                  (category?.new_recipients?.bid
                                                    ?.bid_price_per_vehicle) / 2
                                                ).format("0,0")} PKR`
                                              ) : null}

                                            {category?.new_recipients?.bid
                                              ?.bid_price_loading &&
                                              renderRTL(
                                                `Loading Price / ${state?.data?.is_unit_basis ? " Item" : "Vehicle"} (Without GST)`,
                                                state?.data?.is_unit_basis ?
                                                  `${numeral(
                                                    category?.new_recipients?.bid
                                                      ?.bid_price_loading
                                                  ).format("0,0.00")} PKR`
                                                  :
                                                  `${numeral(
                                                    category?.new_recipients?.bid
                                                      ?.bid_price_loading
                                                  ).format("0,0")} PKR`
                                              )}

                                            {category?.new_recipients?.bid
                                              ?.bid_price_unloading &&
                                              renderRTL(
                                                `UnLoading Price / ${state?.data?.is_unit_basis ? " Item" : "Vehicle"} (Without GST)`,
                                                state?.data?.is_unit_basis ?
                                                  `${numeral(
                                                    category?.new_recipients?.bid
                                                      ?.bid_price_unloading
                                                  ).format("0,0.00")} PKR`
                                                  :
                                                  `${numeral(
                                                    category?.new_recipients?.bid
                                                      ?.bid_price_unloading
                                                  ).format("0,0")} PKR`

                                              )}


                                            <CustomText.OpenSansRegular textStyle={{ marginTop: 20 }}>
                                              {state?.data?.is_unit_basis ? "Item Quantity" : "Number of Vehicles"}
                                            </CustomText.OpenSansRegular>
                                            <div className="vehicleIncDecContainer">
                                              <VehicleIncDec
                                                className="labelWithIncrementField"
                                                value={find?.no_of_vehicles}
                                                updateValue={(val) => {
                                                  let data = [...selectedVehicle]
                                                  data[find_index]['no_of_vehicles'] = val
                                                  setSelectedVehicles(data)
                                                  if (find?.no_of_vehicles <= 0) {
                                                    showMessage.current({
                                                      message:
                                                        " No. of Vehicles should be greater than Zero",
                                                      status: 'error'
                                                    })
                                                  } else {

                                                  }
                                                }}
                                                withInputBox={true}
                                                onMouseDown={(data) => {
                                                  if (data == "inc") {
                                                    let data = [...selectedVehicle]
                                                    data[find_index]['no_of_vehicles'] += 1
                                                    setSelectedVehicles(data)
                                                    clickHoldTimerInc = setInterval(() => {
                                                      let data = [...selectedVehicle]
                                                      data[find_index]['no_of_vehicles'] += 1
                                                      setSelectedVehicles(data)
                                                    }, 200)
                                                  } else {
                                                    if (find?.no_of_vehicles <= 0) {
                                                      showMessage.current({
                                                        message:
                                                          " No. of Vehicles should be greater than Zero",
                                                        status: 'error'
                                                      })
                                                    } else {
                                                      let data = [...selectedVehicle]
                                                      data[find_index]['no_of_vehicles'] -= 1
                                                      setSelectedVehicles(data)
                                                    }
                                                    clickHoldTimerDec = setInterval(() => {
                                                      if (find?.no_of_vehicles <= 0) {
                                                        showMessage.current({
                                                          message:
                                                            " No. of Vehicles should be greater than Zero",
                                                          status: 'error'
                                                        })
                                                      } else {
                                                        let data = [...selectedVehicle]
                                                        data[find_index]['no_of_vehicles'] -= 1
                                                        setSelectedVehicles(data)
                                                      }
                                                    }, 200)
                                                  }
                                                }}
                                                onMouseUp={() => {
                                                  clearInterval(clickHoldTimerInc);
                                                  clearInterval(clickHoldTimerDec);
                                                }}
                                                onTouchStart={(data) => {
                                                  if (data == "inc") {
                                                    clickHoldTimerInc = setInterval(() => {
                                                      let data = [...selectedVehicle]
                                                      data[find_index]['no_of_vehicles'] += 1
                                                      setSelectedVehicles(data)
                                                    }, 200)
                                                  } else {
                                                    clickHoldTimerDec = setInterval(() => {
                                                      if (find?.no_of_vehicles <= 0) {
                                                        showMessage.current({
                                                          message:
                                                            " No. of Vehicles should be greater than Zero",
                                                          status: 'error'
                                                        })
                                                      } else {
                                                        let data = [...selectedVehicle]
                                                        data[find_index]['no_of_vehicles'] -= 1
                                                        setSelectedVehicles(data)
                                                      }
                                                    }, 200)
                                                  }
                                                }}
                                                onTouchEnd={() => {
                                                  clearInterval(clickHoldTimerInc);
                                                  clearInterval(clickHoldTimerDec);
                                                }}
                                              />
                                            </div>
                                            <div style={{ marginBottom: 10 }}>
                                              {renderRTL(
                                                "Total Offer Price (Without GST)",
                                                `${find?.no_of_vehicles > 0 ?
                                                  state?.data?.is_unit_basis ?
                                                    category?.is_twin_load == 1 ?
                                                      numeral(
                                                        ((Number(category?.new_recipients?.bid?.bid_price_per_vehicle) * Number(find?.no_of_vehicles)) +
                                                          Number(category?.new_recipients?.bid?.bid_price_unloading) * (Number(find?.no_of_vehicles) * 2) +
                                                          Number(category?.new_recipients?.bid?.bid_price_loading) * (Number(find?.no_of_vehicles) * 2))
                                                      ).format("0,0")
                                                      : numeral(
                                                        ((Number(category?.new_recipients?.bid?.bid_price_per_vehicle) * Number(find?.no_of_vehicles)) +
                                                          Number(category?.new_recipients?.bid?.bid_price_unloading) * Number(find?.no_of_vehicles) +
                                                          Number(category?.new_recipients?.bid?.bid_price_loading) * Number(find?.no_of_vehicles))
                                                      ).format("0,0.00")
                                                    :
                                                    category?.is_twin_load == 1 ?
                                                      numeral(
                                                        ((Number(category?.new_recipients?.bid?.bid_price_per_vehicle) * Number(find?.no_of_vehicles)) +
                                                          Number(category?.new_recipients?.bid?.bid_price_unloading) * (Number(find?.no_of_vehicles) * 2) +
                                                          Number(category?.new_recipients?.bid?.bid_price_loading) * (Number(find?.no_of_vehicles) * 2))
                                                      ).format("0,0")
                                                      : numeral(
                                                        ((Number(category?.new_recipients?.bid?.bid_price_per_vehicle) * Number(find?.no_of_vehicles)) +
                                                          Number(category?.new_recipients?.bid?.bid_price_unloading) * Number(find?.no_of_vehicles) +
                                                          Number(category?.new_recipients?.bid?.bid_price_loading) * Number(find?.no_of_vehicles))
                                                      ).format("0,0")



                                                  : 0} PKR`

                                              )
                                              }
                                            </div>

                                          </div> :
                                          <div>
                                            {renderRTL(
                                              `Price / ${category?.price_unit} (Without GST)`,
                                              `${numeral(
                                                category?.new_recipients?.bid
                                                  ?.price_per_freight
                                              ).format("0,0.00")} PKR`
                                            )}

                                            {category?.new_recipients?.bid
                                              ?.loading_price_per_freight &&
                                              renderRTL(
                                                `Loading Price / ${category?.price_unit} (Without GST)`,
                                                `${numeral(
                                                  category?.new_recipients?.bid
                                                    ?.loading_price_per_freight
                                                ).format("0,0.00")} PKR`
                                              )}

                                            {category?.new_recipients?.bid
                                              ?.unloading_price_per_freight &&
                                              renderRTL(
                                                `Unloading Price / ${category?.price_unit} (Without GST)`,
                                                `${numeral(
                                                  category?.new_recipients?.bid
                                                    ?.unloading_price_per_freight
                                                ).format("0,0.00")} PKR`
                                              )}

                                            <div className="inputField" style={{ marginTop: 10 }}>
                                              <div className="label">
                                                Enter {category?.price_unit}
                                              </div>
                                              <div className="field">
                                                <NewInput
                                                  onChange={(e) => {
                                                    let new_data = {
                                                      ...load_data,
                                                      total_freight_tons:
                                                        e.target.value,
                                                    };
                                                    set_load_data(new_data);
                                                  }}
                                                  value={load_data?.total_freight_tons ? load_data?.total_freight_tons : ""}
                                                  decimal={true}
                                                />
                                              </div>
                                            </div>
                                            {
                                              renderRTL(
                                                "Total Offer Price (Without GST)",

                                                load_data?.total_freight_tons > 0 ?
                                                  numeral((Number(category?.new_recipients?.bid?.price_per_freight) * (Number(load_data?.total_freight_tons) || 0))
                                                    +
                                                    (Number(category?.new_recipients?.bid?.loading_price_per_freight) * (Number(load_data?.total_freight_tons)) || 0)
                                                    + (Number(category?.new_recipients?.bid?.unloading_price_per_freight) * (Number(load_data?.total_freight_tons)) || 0)
                                                  )
                                                    .format('0,0.00') + " PKR"
                                                  : 0

                                              )}
                                            <div style={{ height: 20 }}></div>

                                          </div>
                                      }
                                    </div>
                                  );
                                }
                              )}
                            </>
                          </div>
                        </Accordion>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <UploadedDocuments loadDetail={loadDetail} />

          <div>
            <InputModal
              values={temp}
              showModal={docuentNameModal}
              title={"Add Document Type"}
              placeholder={"Add document name here"}
              maxLetter={false}
              setValue={(e) => setTemp(e.target.value)}
              handleModal={(value: boolean, type: string) => {
                onAddOtherDocumentType(value);
              }}
            />
            <SelectionModal
              showModal={showModalMedia}
              title={"Media"}
              type="media"
              handleUploadImage={handleUploadImage}
              data={[
                {
                  key: 0,
                  iconClass: 'icons-camera',
                  title: "Camera",
                },

                {
                  key: 1,
                  iconClass: 'icons-gallery',
                  title: "Gallery",
                },
                {
                  key: 2,
                  iconClass: 'icons-document',
                  title: "Document",
                }
              ]}
              onClick={onClickMediaType}
              handleModal={(value: boolean) => {
                setShowModalMedia(value);
              }}
              handleCamrea={handleCamrea}
              handleDoc={handleDoc}
              handleGallery={handleGallery}
            />
            <div className="uploadedDocumentsWrapper customUploadDoc">
              <div style={{ marginTop: 0, marginBottom: 20 }}>
                <div className="uploadDocumentFileBoxWrap">
                  <DocumentsTable
                    titleIcon={Images.docOther}
                    values={
                      uploaded_documents?.other_uploads
                    }
                    screen={'document'}
                    // title={"Other Uploads"}
                    title={PostLoad?.documents?.other_uploads[0]?.files[0]?.file_name}
                    onClickDocumentType={onClickDocumentType}
                    onHandleDelete={(ind, arrayIndex, val) => handleDocumentDelete(ind, arrayIndex, val)}
                    from={'other'}
                    datas={
                      {
                        key: "other_uploads",
                        icon: Images.docOther,
                        title: "Upload Documents",
                      }
                    }
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
                color: "#8b8b8b",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              Please upload images, csv, xls, txt, docx and pdf only
            </div>

          </div>
          <PrimaryButton
            title="Confirm Load"
            disabled={
              handleDisable()
            }
            onClick={() => {
              ConfirmLoadScreen();
            }}
          />
        </div>
      </TitleLayout >
    );
  } else {
    return <div></div>;
  }
};
export default Page;