import { PrimaryButton, OutLineButton, SimpleInput, CustomModal, PhoneInput } from "@temp/components";
import React, { FC, useEffect, useState } from "react";
import RoomIcon from "@mui/icons-material/Room";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material/";
import { useSelector, useDispatch } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import { useHistory, useLocation } from "react-router";
import TitleLayout from "@temp/components/TitleLayout";
import { DrawerAction } from "@temp/store/actions";
import { ExpandMore } from "@mui/icons-material";
import { preventNonNumericalInput, showMessage, validateNameNumber } from "@temp/utils/helper";
import InfoIcon from "@mui/icons-material/Info";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";

const initial_pickup_and_dropoff = {
  address: "",
  lat: null,
  lng: null,
  title: "",
  description: "",
  timestamp: null,
  contact_name: "",
  contact_phone_no: "",
  contact_phone_code: "92"
}

export const addNewLocationContract: FC = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const default_address = postLoadMeta?.load_configurations?.default_address_setting
  const [pickup_accordion, set_pickup_accordion] = useState(false)
  const [dropoff_accordion, set_dropoff_accordion] = useState(false)
  const [picupLoc, setPickupLoc] = useState({
    address: null,
    lat: null,
    lng: null,
    title: null,
    timestamp: null,
    description: null,
    contact_name: null,
    contact_phone_no: null,
    contact_phone_code: "92",
    city: null

  });
  const [dropoffLoc, setDropoffLoc] = useState({
    address: null,
    lat: null,
    lng: null,
    title: null,
    timestamp: null,
    description: null,
    contact_name: null,
    contact_phone_no: null,
    contact_phone_code: "92",
    city: null
  });

  const [value, set_value] = useState('')
  const [valueDrop, set_value_drop] = useState('')
  const [searchAddress, setSearchAddress] = useState("");
  const [searchAddressDrop, setSearchAddressDrop] = useState("");
  const [info, setInfo] = useState("");
  const [showModal, toggleModal] = useState(false);
  const load_type = postLoadMeta.load_types.find((val: any) => val.id === PostLoad.load_type);

  useEffect(() => {
    if (state?.from == "contract" && state?.id >= 0) {

      setPickupLoc({
        address: PostLoad?.location[state?.id]?.pickup?.address,
        lat: PostLoad?.location[state?.id]?.pickup?.lat,
        lng: PostLoad?.location[state?.id]?.pickup?.lng || PostLoad?.location[state?.id]?.pickup?.long,
        title: PostLoad?.location[state?.id]?.pickup?.title,
        timestamp: null,
        description: PostLoad?.location[state?.id]?.pickup?.description,
        contact_name: PostLoad?.location[state?.id]?.pickup?.contact_name,
        contact_phone_no: PostLoad?.location[state?.id]?.pickup?.contact_phone_no,
        contact_phone_code: '92',
        city: PostLoad?.location[state?.id]?.pickup?.city

      });

      //drop
      setDropoffLoc({
        address: PostLoad?.location[state?.id]?.dropoff?.address,
        lat: PostLoad?.location[state?.id]?.dropoff?.lat,
        lng: PostLoad?.location[state?.id]?.dropoff?.lng || PostLoad?.location[state?.id]?.pickup?.long,
        title: PostLoad?.location[state?.id]?.dropoff?.title,
        timestamp: null,
        description: PostLoad?.location[state?.id]?.dropoff?.description,
        contact_name: PostLoad?.location[state?.id]?.dropoff?.contact_name,
        contact_phone_no: PostLoad?.location[state?.id]?.dropoff?.contact_phone_no,
        contact_phone_code: '92',
        city: PostLoad?.location[state?.id]?.dropoff?.city

      });
    } else {
      setPickupLoc({
        address: default_address?.pickup?.raw_address || "",
        lat: default_address?.pickup?.lat || null,
        lng: default_address?.pickup?.long || null,
        title: default_address?.pickup?.title || "",
        description: default_address?.pickup?.description || "",
        timestamp: null,
        contact_name: default_address?.pickup?.contact_name,
        contact_phone_no: default_address?.pickup?.contact_phone_no,
        contact_phone_code: '92',
        city: default_address?.pickup?.city || "",

      })
      setDropoffLoc({
        address: default_address?.dropoff?.raw_address || "",
        lat: default_address?.dropoff?.lat || null,
        lng: default_address?.dropoff?.long || null,
        title: default_address?.dropoff?.title || "",
        description: default_address?.dropoff?.description || "",
        timestamp: null,
        contact_name: default_address?.dropoff?.contact_name,
        contact_phone_no: default_address?.dropoff?.contact_phone_no,
        contact_phone_code: '92',
        city: default_address?.dropoff?.city || "",
      })
    }
  }, []);

  // pickup and dropoff city

  const getCity = (addressComponents) => {
    let itemLocality = null;
    if (addressComponents) {
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          itemLocality = component.long_name;
        }
      });
    }
    return itemLocality;
  };

  const handleAutoComplete = (e) => {
    var element = document.getElementById("map-address") as HTMLInputElement;
    setPickupLoc({
      ...picupLoc,
      lat: null,
      lng: null,
      address: e.target.value,
      city: null
    });

    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const new_address = document.querySelector(`#map-address`)?.value;

        if (place) {
          const city = getCity(place.address_components); // Get city from address_components

          if (!place.geometry || !place.geometry.location) {
            return;
          } else {
            const coordinates = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            const lat = coordinates.lat;
            const lng = coordinates.lng;
            const raw_address = new_address;
            setPickupLoc({
              ...picupLoc,
              lat: lat,
              lng: lng,
              address: raw_address,
              city: city, // Add city to pickupLoc
            });
          }
        }
      });
    }
  };

  const handleAutoCompleteDrop = (e) => {
    var element = document.getElementById("map-address-drop") as HTMLInputElement;
    setDropoffLoc({
      ...dropoffLoc,
      lat: null,
      lng: null,
      address: e.target.value,
      city: null
    });

    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const new_address = document.querySelector(`#map-address-drop`)?.value;

        if (place) {
          const city = getCity(place.address_components); // Get city from address_components

          if (!place.geometry || !place.geometry.location) {
            return;
          } else {
            const coordinates = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            const lat = coordinates.lat;
            const lng = coordinates.lng;
            const raw_address = new_address;
            setDropoffLoc({
              ...dropoffLoc,
              lat: lat,
              lng: lng,
              address: raw_address,
              city: city, // Add city to pickupLoc
            });
          }
        }
      });
    }
  };

  const addLocation = () => {
    const location = [...PostLoad?.location];
    let temp = {
      pickup: picupLoc, dropoff: dropoffLoc,
    };
    let find = location.find((item) =>
      item?.pickup?.title == picupLoc?.title && item?.dropoff?.title == dropoffLoc?.title
    )
    if ((picupLoc?.title == dropoffLoc?.title) || (picupLoc?.address && dropoffLoc?.address && picupLoc?.address == dropoffLoc?.address)) {
      showMessage.current({
        message: 'Pickup and Dropoff location cannot be same',
        status: 'error'
      })
    } else {

      if (state?.id >= 0) {
        location[state?.id] = temp;
        dispatch(
          ProfileAction.postLoadData({
            location: location,
          })
        );
        history.replace(`/contract-location`);
        setPickupLoc(initial_pickup_and_dropoff);
        setDropoffLoc(initial_pickup_and_dropoff);
      } else {
        if (!find) {
          if (state?.id) {
            location[state?.id] = temp;
          } else {
            location.push(temp);
          }
          dispatch(
            ProfileAction.postLoadData({
              location: location,
            })
          );
          history.replace(`/contract-location`);
          setPickupLoc(initial_pickup_and_dropoff);
          setDropoffLoc(initial_pickup_and_dropoff);
        } else {
          showMessage.current({
            message:
              "You have already selected this pickup and drop off location",
            status: 'error'
          })
        }
      }
    }
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <TitleLayout
      pageNumber={6}
      progress={60}
      title={`Add Location - ${load_type?.title}`}
      screen="addAddress"
    >
      <div className="formFeildWrapper addressPageWrapper">
        {/* pickup location  */}
        <div>
          <div className="sectionBox">
            <p className="titleSection">Pickup Location</p>
            {
              [...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length ?
                <div className="addressAccordion" style={{ width: "100%", overflowY: "scroll", marginBottom: 10 }} >
                  <Accordion
                    expanded={pickup_accordion}
                    onChange={() => set_pickup_accordion(!pickup_accordion)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel-header"
                    >
                      <label className="fw-5">Select From Saved Addresses </label>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={`faqas-item`} style={{ width: '100%' }}>
                        <>
                          {
                            [...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length > 1 ?
                              <div className="searchContainer" style={{ marginBottom: '1rem' }} >
                                <input
                                  className="searchInput"
                                  onChange={(e) => setSearchAddress(e.target.value)}
                                  type="text"
                                  name="search"
                                  value={searchAddress}
                                  placeholder="Search by name"
                                />
                                <span className={searchAddress ? 'icons-cross' : 'icons-search'} onClick={() => {
                                  setSearchAddress("");
                                }}></span>
                              </div> : null
                          }
                          <div>
                            {[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].filter((item) =>
                              item?.title
                                ?.toLowerCase()
                                .includes(searchAddress.toLowerCase())
                            ).sort((a, b) => {
                              if (a?.title?.toLowerCase().indexOf(searchAddress.toLowerCase()) > b?.title?.toLowerCase().indexOf(searchAddress.toLowerCase())) {
                                return 1;
                              } else if (a?.title?.toLowerCase().indexOf(searchAddress.toLowerCase()) < b?.title?.toLowerCase().indexOf(searchAddress.toLowerCase())) {
                                return -1;
                              } else {
                                if (a.title > b.title)
                                  return 1;
                                else
                                  return -1;
                              }
                            })?.map((val) => {
                              return (
                                <Button
                                  onClick={() => {
                                    const { raw_address, long, lat, title, description, contact_name, contact_phone_no, city } = val;
                                    setPickupLoc({
                                      address: raw_address,
                                      lat: lat,
                                      lng: long,
                                      title: title,
                                      timestamp: null,
                                      description: description || "",
                                      contact_name: contact_name,
                                      contact_phone_no: contact_phone_no,
                                      contact_phone_code: "92",
                                      city: city || ""
                                    });
                                    set_pickup_accordion(!pickup_accordion)
                                  }}
                                  className="locationItem"
                                >
                                  <RoomIcon className="icon" />
                                  <label className="title">
                                    {val?.title || ""}
                                  </label>
                                </Button>
                              )
                            })
                            }
                          </div>
                        </>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div> : null
            }
            <div className="inputField">
              <div className="field">
                <div className={`input-container-new ${picupLoc?.title ? 'filled' : ''}`}>
                  <input
                    className="new-inut-field"
                    onChange={(e) => {
                      setPickupLoc({
                        ...picupLoc,
                        lat: picupLoc?.lat,
                        lng: picupLoc?.lng,
                        title: e.target.value,
                      });
                    }}
                    type="text"
                    autoComplete="off"
                    name="search"
                    value={picupLoc?.title}
                    placeholder="Enter your pickup location name"
                    maxLength={30}

                  />
                  <span class="inputSlide"></span>
                </div>
              </div>
            </div>
            <div className="searchContainer" style={{ width: "100%", marginBottom: '1rem' }}>
              <input
                className="searchInput"
                id="map-address"
                onChange={(e) => { handleAutoComplete(e) }}
                type="text"
                autoComplete="off"
                name="search"
                style={{ width: "100%" }}
                value={picupLoc?.address}
                placeholder="Enter detailed address"
                maxLength={100}
              />
              <span className={`icons-search ${!picupLoc?.address?.length ? 'active' : ''}`}></span>
              <span
                className={`icons-cross ${picupLoc?.address?.length ? 'active' : ''}`}
                onClick={() => {
                  if (picupLoc?.address) {
                    let clearAdress = {
                      ...picupLoc,
                      address: "",
                      city: ""
                    }
                    setPickupLoc(clearAdress);
                  }
                }}>
              </span>
            </div>
            <div className="inputField mtop8">
              <div className="label">Contact Name <InfoIcon onClick={() => {
                setInfo('Contact information will only be visible when load is awarded')
                toggleModal(true)
              }} /></div>
              <div className="field">
                <SimpleInput
                  type="text"
                  value={picupLoc?.contact_name}
                  onChange={(e) => {
                    setPickupLoc({
                      ...picupLoc,
                      lat: picupLoc?.lat,
                      lng: picupLoc?.lng,
                      contact_name: e.target.value,
                    });
                  }}
                  placeholder="Enter Contact Name"
                />
              </div>
            </div>
            <div className="inputField driverNumberField">
              <div className="label">Contact Number</div>
              <div className="field">
                {/* <PhoneInput
                  value={picupLoc?.contact_phone_no}
                  phoneCode={picupLoc?.contact_phone_code || "92"}
                  onChange={(e) => {
                    if (e.target.value.length < 11) {
                      setPickupLoc({
                        ...picupLoc,
                        lat: null,
                        lng: null,
                        contact_phone_no: e.target.value,
                      });
                    }
                  }}
                  id="phone-number"
                  placeholder={"3000000000"}
                /> */}

                <PhoneInput
                  value={picupLoc?.contact_phone_no}
                  phoneCode={picupLoc?.contact_phone_code || "92"}
                  onChange={(e) => {
                    // Remove any spaces, leading zeros, and make sure it's 10 digits max
                    let phoneNumber = e.target.value.replace(/\s+/g, '').replace(/^0+/, '');

                    if (phoneNumber.length <= 10) {
                      setPickupLoc({
                        ...picupLoc,
                        contact_phone_no: phoneNumber,
                      });
                    }
                  }}
                  id="phone-number"
                  placeholder={"3000000000"}
                />

              </div>
            </div>
          </div>
          <OutLineButton
            type={"submit"}
            title="Add to address book"
            buttonStyle={{ marginBottom: "1rem", }}
            disabled={
              ![...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff]?.find(item => item?.raw_address == picupLoc?.address && item?.title == picupLoc?.title)?.id && picupLoc?.address && picupLoc?.title
                ? false
                : true
            }
            onClick={() => {
              if (validateNameNumber(picupLoc?.contact_name ? picupLoc?.contact_name : "", picupLoc?.contact_phone_no ? picupLoc?.contact_phone_no : "")) {
                dispatch(
                  DrawerAction.addLocation({
                    data: { ...picupLoc, type: 'pickup', raw_address: picupLoc?.address, model_name: 'user', long: picupLoc?.lng },
                  })
                );
              }
            }}
          />
        </div>

        {/* dropoff location */}
        <div>
          <FadeInComponent>
            <div className="sectionBox">
              <p className="titleSection">Drop Off Location</p>
              {
                [...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length ?
                  <div className="addressAccordion" style={{ width: "100%", overflowY: "scroll", marginBottom: 10 }}>
                    <Accordion
                      expanded={dropoff_accordion}
                      onChange={() => set_dropoff_accordion(!dropoff_accordion)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel-header"
                      >
                        <label className="fw-5">Select From Saved Addresses</label>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={`faqas-item`} style={{ width: '100%' }}>
                          <>
                            {
                              [...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length > 1 ?
                                <div className="searchContainer" style={{ width: "100%" }}>
                                  <input
                                    className="searchInput"
                                    onChange={(e) => setSearchAddressDrop(e.target.value)}
                                    type="text"
                                    name="search"
                                    value={searchAddressDrop}
                                    placeholder="Search by name"
                                    style={{ width: "100%" }}
                                  />
                                  <span className={searchAddressDrop ? 'icons-cross' : 'icons-search'} onClick={() => {
                                    setSearchAddressDrop("");
                                  }}></span>
                                </div> : null
                            }

                            <div >
                              {[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].filter((item) =>
                                item?.title?.toLowerCase()
                                  .includes(searchAddressDrop.toLowerCase())

                              )?.sort((a, b) => {
                                if (a?.title?.toLowerCase().indexOf(searchAddressDrop.toLowerCase()) > b?.title?.toLowerCase().indexOf(searchAddressDrop.toLowerCase())) {
                                  return 1;
                                } else if (a?.title?.toLowerCase().indexOf(searchAddressDrop.toLowerCase()) < b?.title?.toLowerCase().indexOf(searchAddressDrop.toLowerCase())) {
                                  return -1;
                                } else {
                                  if (a.title > b.title)
                                    return 1;
                                  else
                                    return -1;
                                }
                              })?.map((val) => {
                                return (
                                  <Button
                                    onClick={() => {
                                      const { raw_address, long, lat, title, description, contact_name, contact_phone_no, city } = val;
                                      setDropoffLoc({
                                        address: raw_address,
                                        lat: lat,
                                        lng: long,
                                        title: title,
                                        timestamp: null,
                                        description: description || "",
                                        contact_name: contact_name,
                                        contact_phone_no: contact_phone_no,
                                        contact_phone_code: '92',
                                        city: city || ""
                                      });
                                      set_dropoff_accordion(!dropoff_accordion)
                                    }}
                                    className="locationItem"
                                  >
                                    <RoomIcon className="icon" />
                                    <label className="title">
                                      {val?.title || ""}
                                    </label>
                                  </Button>
                                )
                              })
                              }
                            </div>
                          </>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div> : null
              }

              <div className="inputField">
                <div className="field">
                  <div className={`input-container-new ${dropoffLoc?.title ? 'filled' : ''}`}>
                    <input
                      className="new-inut-field"
                      onChange={(e) => {
                        setDropoffLoc({
                          ...dropoffLoc,
                          lat: dropoffLoc?.lat,
                          lng: dropoffLoc?.lng,
                          title: e.target.value,
                        });
                      }}
                      type="text"
                      autoComplete="off"
                      name="search"
                      style={{ width: "100%" }}
                      value={dropoffLoc?.title}
                      placeholder="Enter your dropoff location name"
                      maxLength={30}
                    />
                    <span class="inputSlide"></span>
                  </div>
                </div>
              </div>

              <div className="searchContainer" style={{ width: "100%" }}>
                <input
                  className="searchInput"
                  id="map-address-drop"
                  // onChange={handleAutoCompleteDrop}
                  onChange={(e) => { handleAutoCompleteDrop(e) }}
                  type="text"
                  autoComplete="off"
                  name="search"
                  style={{ width: "100%" }}
                  value={dropoffLoc?.address}
                  placeholder="Enter detailed address"
                  maxLength={100}
                />
                <span className={`icons-search ${!dropoffLoc?.address?.length ? 'active' : ''}`}></span>
                <span
                  className={`icons-cross ${dropoffLoc?.address?.length ? 'active' : ''}`}
                  onClick={() => {
                    if (dropoffLoc?.address) {
                      let clearAdress = {
                        ...dropoffLoc,
                        address: "",
                        city: ""

                      }
                      setDropoffLoc(clearAdress);
                    }
                  }}>
                </span>
              </div>
              <div className="inputField mtop8">
                <div className="label">Contact Name <InfoIcon onClick={() => {
                  setInfo('Contact information will only be visible when load is awarded')
                  toggleModal(true)
                }} /></div>
                <div className="field">
                  <SimpleInput
                    type="text"
                    value={dropoffLoc?.contact_name}
                    onChange={(e) => {
                      setDropoffLoc({
                        ...dropoffLoc,
                        lat: dropoffLoc?.lat,
                        lng: dropoffLoc?.lng,
                        contact_name: e.target.value,
                      });
                    }}
                    placeholder="Enter Contact Name"
                  />
                </div>
              </div>
              <div className="inputField driverNumberField">
                <div className="label">Contact Number</div>
                <div className="field">
                  {/* <PhoneInput
                    value={dropoffLoc?.contact_phone_no}
                    phoneCode={dropoffLoc?.contact_phone_code || "92"}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setDropoffLoc({
                          ...dropoffLoc,
                          lat: null,
                          lng: null,
                          contact_phone_no: e.target.value,
                        });
                      }
                    }}
                    id="phone-number"
                    placeholder={"3000000000"}
                  /> */}

                  <PhoneInput
                    value={dropoffLoc?.contact_phone_no}
                    phoneCode={dropoffLoc?.contact_phone_code || "92"}
                    onChange={(e) => {
                      // Remove any spaces, leading zeros, and make sure it's 10 digits max
                      let phoneNumber = e.target.value.replace(/\s+/g, '').replace(/^0+/, '');

                      if (phoneNumber.length <= 10) {
                        setDropoffLoc({
                          ...dropoffLoc,
                          contact_phone_no: phoneNumber,
                        });
                      }
                    }}
                    id="phone-number"
                    placeholder={"3000000000"}
                  />

                </div>
              </div>
            </div>
          </FadeInComponent>
          <FadeInComponent>
            <OutLineButton
              type={"submit"}
              title="Add to address book"
              buttonStyle={{ marginBottom: "1rem", }}
              disabled={
                ![...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff]?.find(item => item?.raw_address == dropoffLoc?.address && item?.title == dropoffLoc?.title)?.id && dropoffLoc?.address && dropoffLoc?.title
                  ? false
                  : true
              }
              onClick={() => {
                if (validateNameNumber(dropoffLoc?.contact_name ? dropoffLoc?.contact_name : "", dropoffLoc?.contact_phone_no ? dropoffLoc?.contact_phone_no : "")) {
                  dispatch(
                    DrawerAction.addLocation({
                      data: { ...dropoffLoc, type: 'dropoff', raw_address: dropoffLoc?.address, model_name: 'user', long: dropoffLoc?.lng },
                    })
                  );
                }
              }}
            />
          </FadeInComponent>
        </div>
      </div>
      {
        state?.from == "contract" && (
          <PrimaryButton
            buttonStyle={{
              marginTop: 14
            }}
            rightIcon={true}
            title={state?.id >= 0 ? "Update" : "CONTINUE"}
            type="submit"
            disabled={!picupLoc?.title || !dropoffLoc?.title ? true : false}
            onClick={() => {
              addLocation();
            }}
          />
        )
      }

      <CustomModal
        modalStyle={{ width: 300, minHeight: 100, }}
        className="infoModal"
        showModal={showModal}
        toggleModal={toggleModal}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {info}
          </p>
        </div>
      </CustomModal>

    </TitleLayout >
  );
};